/* eslint-disable indent */
import { toast, ToastOptions } from 'react-toastify';

interface Props {
  message: string;
  type: 'success' | 'warning' | 'error';
}

function Alert({ message, type }: Props) {
  let alert;

  const options: ToastOptions = {
    position: 'top-right',
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  };

  switch (type) {
    case 'success':
      alert = toast.success(message, options);
      break;
    case 'warning':
      alert = toast.warning(message, options);
      break;
    case 'error':
      alert = toast.error(message, options);
      break;
    default:
      break;
  }
  return alert;
}

export { Alert };
