import { FaGraduationCap, FaUserCircle } from 'react-icons/fa';
import { ImTree } from 'react-icons/im';
import { IconType } from 'react-icons/lib';
import { MdHomeFilled, MdPeopleAlt, MdUpload, MdMessage } from 'react-icons/md';
import { TbReportAnalytics } from 'react-icons/tb';

import { Role } from '../../../../types/roles';

type MenuItem = {
  path?: string;
  name: string;
  Icon: IconType;
  role?: Role;

  subItems?: MenuItem[];
};

export const menuItems: MenuItem[] = [
  {
    path: '/web',
    name: 'menu.initialPage',
    Icon: MdHomeFilled,
  },
  {
    path: '/structure',
    name: 'menu.structure',
    Icon: ImTree,
    role: Role.Structure,
  },
  {
    path: '/profiles',
    name: 'menu.profiles',
    Icon: FaUserCircle,
    role: Role.Profiles,
  },
  {
    path: '/uploads',
    name: 'menu.uploads',
    Icon: MdUpload,
    role: Role.Uploads,
  },
  {
    path: '/messages',
    name: 'menu.messages',
    Icon: MdMessage,
    role: Role.Messages,
  },
  {
    path: '/training',
    name: 'menu.training',
    Icon: FaGraduationCap,
    role: Role.Training,
  },
  {
    path: '/employees',
    name: 'menu.employees',
    Icon: MdPeopleAlt,
    role: Role.Employees,
  },
  {
    path: '/reports',
    name: 'menu.reports',
    Icon: TbReportAnalytics,
    role: Role.Reports,
  },
];
