import { useCallback, useEffect } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Loader } from 'semantic-ui-react';

import { ControllerFormDropdown } from '../../components/Form/ControllerFormDropdown';
import { FilterFormGroup } from '../../components/Form/FilterFormGroup';
import { Toolbar } from '../../components/Toolbar';
import { useAuth } from '../../hooks/useAuth';
import regionOptions from '../../mocks/sbu.json';
import { FIELD_REQUIRED_MESSAGE } from '../../settings/constants';
import { IReportFilterParamsProps } from '../../types/filters';
import { Table } from './components/Table';
import { useReport } from './hooks/useReport';
import { Container } from './styles';

export function Reports() {
  const { t } = useTranslation();
  const { user } = useAuth();
  const {
    control,
    reset,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm<IReportFilterParamsProps>();

  const watchRegion = useWatch({ control, name: 'region' });
  const watchSBUId = useWatch({ control, name: 'sbuId' });

  const {
    page,
    setPage,
    query,
    setQuery,

    reports,
    isReportsLoading,

    releasedMandatory,
    hasSubordinates,

    sbusOptions,
    mandatoryOptions,
  } = useReport({
    watchRegion,
    watchSBUId,
    setValue,
  });

  useEffect(() => {
    setQuery({
      sbuId: releasedMandatory.sbuId,
      mandatoryId: releasedMandatory.mandatoryId,
      filterUserId: hasSubordinates ? user?.userId : undefined,
      hasSubordinates,
    });
  }, [
    hasSubordinates,
    releasedMandatory.mandatoryId,
    releasedMandatory.sbuId,
    setQuery,
    user?.userId,
  ]);

  const onSubmit = useCallback(
    (data: IReportFilterParamsProps) => {
      setQuery({ ...data });
    },
    [setQuery]
  );

  function handleClearFilters() {
    reset({
      region: null,
      mandatoryId: null,
      sbuId: null,
      filterUserId: null,
      trainingTypesIds: null,
    });

    setQuery({
      sbuId: releasedMandatory.sbuId,
      mandatoryId: releasedMandatory.mandatoryId,
    });
  }

  const renderTable = useCallback(() => {
    if (isReportsLoading) {
      return <Loader active />;
    }
    return (
      <Table reports={reports} page={page} setPage={setPage} query={query} />
    );
  }, [isReportsLoading, page, query, reports, setPage]);

  return (
    <Container>
      <div className="page__title">{t('menu.reports')}</div>
      <Toolbar
        onApplyClick={handleSubmit(onSubmit)}
        onClearClick={handleClearFilters}
        disableNewButton
        enablePrintButton={!!reports}
        disableFilter={!user?.type}
      >
        <FilterFormGroup>
          <ControllerFormDropdown
            controllerControl={control}
            controllerName="region"
            label={t('pages.training.sbu')}
            options={regionOptions}
            required
            error={errors.region && t(FIELD_REQUIRED_MESSAGE)}
            fluid
          />
          <ControllerFormDropdown
            controllerControl={control}
            controllerName="sbuId"
            label={t('pages.training.countryLocation')}
            options={sbusOptions}
            required={!!watchRegion}
            error={errors.sbuId && t(FIELD_REQUIRED_MESSAGE)}
            disabled={!watchRegion}
            fluid
          />
          <ControllerFormDropdown
            controllerControl={control}
            controllerName="mandatoryId"
            label={t('pages.training.year')}
            options={mandatoryOptions}
            required={!!watchSBUId}
            error={errors.mandatoryId && t(FIELD_REQUIRED_MESSAGE)}
            disabled={!watchRegion || !watchSBUId}
            fluid
          />
        </FilterFormGroup>
      </Toolbar>

      {renderTable()}
    </Container>
  );
}
