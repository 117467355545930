import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Table as TableSemantic,
  Pagination,
  PaginationProps,
} from 'semantic-ui-react';

import { TableNoDataFound } from '../../../../components/TableNoDataFound';
import { ImportPaginationResponse } from '../../../../types/import';
import { TableBody } from '../TableBody';

type TableProps = {
  data?: ImportPaginationResponse;
  onChangePage: (page: number) => void;
};

const Table = memo(function Table({ data, onChangePage }: TableProps) {
  const { t } = useTranslation();
  function handlePageChange(
    _: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    { activePage }: PaginationProps
  ) {
    onChangePage(activePage as number);
  }

  const cells = [
    {
      id: 1,
      content: t('pages.uploads.year'),
      textAlign: 'center',
    },
    {
      id: 2,
      content: t('pages.uploads.countryLocation'),
      textAlign: 'center',
    },
    {
      id: 3,
      content: t('pages.uploads.importDate'),
      textAlign: 'center',
    },
    {
      id: 4,
      content: t('pages.uploads.importBy'),
      textAlign: 'left',
    },
    {
      id: 5,
      content: t('pages.uploads.status'),
      textAlign: 'left',
    },
    {
      id: 6,
      content: t('pages.uploads.logs'),
      textAlign: 'center',
    },
    {
      id: 7,
      content: t('pages.uploads.exportData'),
      textAlign: 'center',
    },
    {
      id: 8,
      content: t('pages.uploads.release'),
      textAlign: 'center',
    },
  ];

  return (
    <div className="table__scrollable">
      <TableSemantic className="table" unstackable>
        <TableSemantic.Header className="thead">
          <TableSemantic.Row className="tr">
            {cells.map(({ id, content, textAlign }) => (
              <TableSemantic.HeaderCell
                key={id}
                className="th"
                scope="col"
                textAlign={textAlign as any}
              >
                {content}
              </TableSemantic.HeaderCell>
            ))}
          </TableSemantic.Row>
        </TableSemantic.Header>

        {data?._embedded?.importDTOList && (
          <TableBody imports={data?._embedded?.importDTOList} />
        )}
        <TableSemantic.Footer className="tfoot" fullWidth>
          <TableSemantic.Row>
            <TableSemantic.HeaderCell colSpan="8">
              {data && data.page.totalElements > 0 ? (
                <Pagination
                  activePage={data.page.number}
                  boundaryRange={0}
                  ellipsisItem={null}
                  firstItem={null}
                  lastItem={null}
                  siblingRange={1}
                  totalPages={data.page.totalPages ?? 0}
                  onPageChange={handlePageChange}
                />
              ) : (
                <TableNoDataFound />
              )}
            </TableSemantic.HeaderCell>
          </TableSemantic.Row>
        </TableSemantic.Footer>
      </TableSemantic>
    </div>
  );
});

export { Table };
