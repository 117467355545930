import { useState } from 'react';
import { UseFormSetValue } from 'react-hook-form';
import { useQuery } from 'react-query';

import { useMandatoryByLoggedUser } from '../../../hooks/useMandatoryByLoggedUser';
import { useMandatoryOptions } from '../../../hooks/useMandatoryOptions';
import { useSbuOptions } from '../../../hooks/useSbuOptions';
import { useSubordinatesByLoggedUser } from '../../../hooks/useSubordinatesByLoggedUser';
import { useTrainingTypeOptions } from '../../../hooks/useTrainingTypeOptions';
import { getListReport } from '../../../services/report';
import { IReportFilterParamsProps } from '../../../types/filters';

type UseReportProps = {
  watchRegion?: string | null;
  watchSBUId?: number | null;
  setValue: UseFormSetValue<any>;
};

export const useReport = ({
  watchRegion,
  watchSBUId,
  setValue,
}: UseReportProps) => {
  const [page, setPage] = useState(1);
  const [query, setQuery] = useState<IReportFilterParamsProps>({
    sbuId: undefined,
    mandatoryId: undefined,
    filterUserId: undefined,
    trainingTypesIds: undefined,
    hasSubordinates: false,
  });

  const { releasedMandatory } = useMandatoryByLoggedUser();
  const { hasSubordinates } = useSubordinatesByLoggedUser();

  const { sbusOptions } = useSbuOptions(watchRegion as string);
  const { mandatoryOptions } = useMandatoryOptions({
    watchSBU: watchSBUId as number,
    watchRegion: watchRegion as string,
    setValue,
  });
  const { trainingTypeOptions } = useTrainingTypeOptions();

  const { data: reports, isLoading: isReportsLoading } = useQuery(
    ['REPORT_LIST', releasedMandatory, hasSubordinates, { page, ...query }],
    async () =>
      getListReport({
        page: page - 1,
        hasSubordinates,
        ...query,
      }),
    {
      enabled: !!releasedMandatory && !!query.mandatoryId && !!query.sbuId,
      retry: false,
    }
  );

  return {
    page,
    setPage,
    query,
    setQuery,

    releasedMandatory,
    hasSubordinates,

    reports,
    isReportsLoading,

    sbusOptions,
    mandatoryOptions,
    trainingTypeOptions,
  };
};
