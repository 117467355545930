import { Dispatch, SetStateAction, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Icon, Popup } from 'semantic-ui-react';
import { useTheme } from 'styled-components';

import { Filter } from '../Filter';
import { ButtonGDY } from '../Form/ButtonGDY';
import { Container, Content } from './styles';

type ToolbarPagesProps = {
  onAddClick?: () => void;

  disableNewButton?: boolean;
  disableFilter?: boolean;

  enablePrintButton?: boolean;
  enableTableAndChartToggleButtons?: boolean;
  toggleTableAndChart?: boolean;
  setToggleTableAndChart?: Dispatch<SetStateAction<boolean>>;

  children?: React.ReactNode;
  onApplyClick(): void;
  onClearClick(): void;
};

function Toolbar({
  onAddClick,
  disableNewButton,
  disableFilter,
  enablePrintButton,
  enableTableAndChartToggleButtons,
  toggleTableAndChart,
  setToggleTableAndChart,
  children,
  onApplyClick,
  onClearClick,
}: ToolbarPagesProps) {
  const { t } = useTranslation();
  const theme = useTheme();
  const [showFilterBar, setShowFilterBar] = useState(false);

  function handlePrintClick() {
    window.print();
  }

  function toggleFilterBar() {
    setShowFilterBar((prev) => !prev);
  }

  function closeFilterBar() {
    setShowFilterBar(false);
  }

  return (
    <>
      <Container>
        <Content>
          <span>
            {!disableNewButton && (
              <Button
                className="add-button"
                icon
                labelPosition="left"
                onClick={() => onAddClick?.()}
                style={{
                  backgroundColor: theme.palette.GENERAL.PRIMARY,
                  color: theme.palette.GENERAL.SECONDARY,
                }}
              >
                <Icon name="add" />
                {t('components.buttons.newButton')}
              </Button>
            )}
          </span>

          <span style={{ display: 'flex', gap: '10px' }}>
            {enableTableAndChartToggleButtons && (
              <Button.Group>
                <Popup
                  inverted
                  content="Table view"
                  trigger={
                    <ButtonGDY
                      icon="table"
                      active={toggleTableAndChart}
                      onClick={() =>
                        setToggleTableAndChart && setToggleTableAndChart(true)
                      }
                    />
                  }
                />
                <Popup
                  inverted
                  content="Analytics view"
                  trigger={
                    <ButtonGDY
                      icon="chart pie"
                      active={!toggleTableAndChart}
                      onClick={() =>
                        setToggleTableAndChart && setToggleTableAndChart(false)
                      }
                    />
                  }
                />
              </Button.Group>
            )}

            {enablePrintButton && (
              <ButtonGDY
                className="printButton"
                icon
                labelPosition="left"
                onClick={handlePrintClick}
              >
                <Icon name="print" />
                {t('pages.development.topbar.printReport')}
              </ButtonGDY>
            )}

            {!disableFilter && (
              <ButtonGDY
                className="filter-list"
                icon="filter"
                active={showFilterBar}
                onClick={toggleFilterBar}
              />
            )}
          </span>
        </Content>
      </Container>
      <Filter
        onApplyClick={onApplyClick}
        onClearClick={onClearClick}
        closeFilterBar={closeFilterBar}
        show={showFilterBar}
      >
        {children}
      </Filter>
    </>
  );
}

export { Toolbar };
