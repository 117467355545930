import { useRef, useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';
import { Modal, ModalProps } from 'semantic-ui-react';

import { ButtonGDY } from '../../../../components/Form/ButtonGDY';
import { createNewProfile, updateProfile } from '../../../../services/profiles';
import { queryClient } from '../../../../services/queryClient';
import { ProfileDataForm } from '../../../../types/profiles';
import { FormContent } from '../FormContent';

type ProfileFormModalProps = ModalProps & {
  isOpen: boolean;
  initialValues?: ProfileDataForm;
  onRequestClose: () => void;
};

function ProfileFormModal({
  isOpen,
  initialValues,
  onRequestClose,
  ...rest
}: ProfileFormModalProps) {
  const { t } = useTranslation();
  const formRef = useRef<HTMLFormElement>(null);

  const { control, formState, handleSubmit, watch, setValue } =
    useForm<ProfileDataForm>({
      ...(initialValues && {
        defaultValues: initialValues,
      }),
    });

  const createProfile = useMutation(
    async (data: ProfileDataForm) => {
      return createNewProfile(data);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('profiles');
        onRequestClose();
        toast.success(t('components.toast.success.create'));
      },
      onError: () => {
        toast.error(t('components.toast.error.create'));
      },
    }
  );

  const editProfile = useMutation(
    async (data: ProfileDataForm) => {
      return updateProfile(data);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('profiles');
        onRequestClose();
        toast.success(t('components.toast.success.edit'));
      },
      onError: () => {
        toast.error(t('components.toast.error.edit'));
      },
    }
  );

  const onSubmit = useCallback(
    async (data: ProfileDataForm) => {
      if (data.id) {
        await editProfile.mutateAsync(data);
      } else {
        await createProfile.mutateAsync(data);
      }
    },
    [editProfile, createProfile]
  );

  return (
    <Modal
      {...rest}
      onClose={onRequestClose}
      size="small"
      closeOnDimmerClick={false}
      open={isOpen}
      closeIcon
    >
      <Modal.Header>{t('pages.profiles.form.title')}</Modal.Header>
      <FormContent
        formRef={formRef}
        handleSubmit={handleSubmit}
        setValue={setValue}
        watch={watch}
        control={control}
        onSubmit={onSubmit}
        formState={formState}
      />
      <Modal.Actions>
        <ButtonGDY
          type="submit"
          primary
          onClick={() => {
            formRef.current?.handleSubmit();
          }}
          loading={createProfile.isLoading || editProfile.isLoading}
          disabled={createProfile.isLoading || editProfile.isLoading}
        >
          {t('components.buttons.saveButton')}
        </ButtonGDY>
      </Modal.Actions>
    </Modal>
  );
}

export { ProfileFormModal };
