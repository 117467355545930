import loading from '../../assets/loading.gif';
import logoHomeWait from '../../assets/logo_home_wait.png';
import { Container } from './styles';

export function SplashScreen() {
  return (
    <Container>
      <div
        style={{
          height: '500px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: ' center',
        }}
      >
        <img src={logoHomeWait} style={{ width: '50%' }} alt="Logo" />
        <br />
      </div>
      <div
        style={{
          height: '10px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: ' center',
        }}
      >
        <img src={loading} style={{ width: '10%' }} alt="Loading..." />
      </div>
    </Container>
  );
}
