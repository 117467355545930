import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Divider } from 'semantic-ui-react';

import { LoaderCentered } from '../../../../components/LoaderCentered';
import { useAuth } from '../../../../hooks/useAuth';
import { useMandatoryDetails } from '../../../../hooks/useMandatoryDetails';
import { Sbu } from '../../../../types/mandatories';
import { Container } from './styles';

interface IProps {
  mandatoryId: number;
  year: string;
  sbu: Sbu;
  released?: boolean;
}

export function MandatoryCard({ mandatoryId, year, sbu, released }: IProps) {
  const { t } = useTranslation();
  const { user } = useAuth();
  const navigate = useNavigate();

  const { mandatoryDetails, isLoadingMandatoryDetails } = useMandatoryDetails({
    mandatoryId,
    sbuId: sbu.id,
    userId: String(user?.userId),
  });

  function handleClick() {
    navigate(`/development/${mandatoryId}/${sbu.id}`, {
      state: user?.userId,
    });
  }

  return (
    <Container onClick={handleClick} released={released}>
      <div className="mandatoryCard__headerAndTotalDevelopment">
        <div className="mandatoryCard__header">
          <div className="mandatoryCard__header--title">
            {year.split('-')[0]}
          </div>
          <div className="mandatoryCard__header--subtitle">{sbu.country}</div>

          <Divider />
        </div>

        <div className="mandatoryCard__totalDevelopment">
          <div className="mandatoryCard__totalDevelopment--hours">
            {isLoadingMandatoryDetails ? (
              <LoaderCentered />
            ) : (
              `${mandatoryDetails?.total.toFixed(2) ?? 0} Horas`
            )}
          </div>
          <div className="mandatoryCard__totalDevelopment--title">
            {t('pages.development.totalDevelopment')}
          </div>
        </div>

        <Divider />
      </div>

      <div className="mandatoryCard__trainingList">
        {isLoadingMandatoryDetails ? (
          <LoaderCentered />
        ) : (
          mandatoryDetails?.data?.map(({ trainingTypeName, total }) => (
            <div
              key={trainingTypeName}
              className="mandatoryCard__trainingList--item"
            >
              <div className="mandatoryCard__trainingList--title">
                {trainingTypeName}
              </div>
              <div className="mandatoryCard__trainingList--hours">{`${total.toFixed(
                2
              )}h`}</div>
            </div>
          ))
        )}
      </div>
    </Container>
  );
}
