import { useState, useMemo, useCallback, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { Link } from 'react-router-dom';
import {
  Header,
  Icon,
  Loader,
  Pagination,
  PaginationProps,
} from 'semantic-ui-react';

import { Filter } from '../../components/Filter';
import { ButtonGDY } from '../../components/Form/ButtonGDY';
import { ControllerFormDropdown } from '../../components/Form/ControllerFormDropdown';
import { FilterFormGroup } from '../../components/Form/FilterFormGroup';
import { LoaderCentered } from '../../components/LoaderCentered';
import { WelcomeBar } from '../../components/WelcomeBar';
import { useAuth } from '../../hooks/useAuth';
import { useEmployeeByUserId } from '../../hooks/useEmployeeByUserId';
import { useMandatoryByLoggedUser } from '../../hooks/useMandatoryByLoggedUser';
import { useMessageByType } from '../../hooks/useMessageByType';
import { getListSubordinates } from '../../services/employees';
import { MandatoryCard } from './components/MandatoryCard';
import {
  Container,
  MyMandatories,
  MyTeam,
  MyTeamContent,
  MyTeamHeader,
} from './styles';

interface FilterProps {
  mandatoryId?: number | null;
}

export type AssociatedDataProps = {
  isLoadingEmployeeData?: boolean;
  name?: string;
  businessLine?: string;
  jobTitle?: string;
  facilityDescription?: string;
};

function Home() {
  const { t } = useTranslation();
  const { user } = useAuth();
  const { control, handleSubmit, reset, setValue } = useForm<FilterProps>();
  const [showFilterBar, setShowFilterBar] = useState(false);

  const [query, setQuery] = useState<FilterProps>({});
  const [page, setPage] = useState(1);

  const {
    mandatories: mandatoriesByLoggedUser,
    isLoadingmandatories: isLoadingmandatoriesByLoggedUser,
  } = useMandatoryByLoggedUser();

  const lastMandatory = mandatoriesByLoggedUser?.at(
    mandatoriesByLoggedUser.length - 1
  );

  const { associatedData, isAssociatedDataLoading } = useEmployeeByUserId({
    mandatoryId: Number(lastMandatory?.id),
    sbuId: Number(lastMandatory?.sbu.id),
    userId: user?.userId,
  });

  const { data: subordinates, isLoading: isLoadingSubordinates } = useQuery(
    ['SUBORDINATES', mandatoriesByLoggedUser, { page, ...query }],
    async () =>
      getListSubordinates({
        mandatoryId: query.mandatoryId ?? mandatoriesByLoggedUser?.at(-1)?.id,
        sbuId: mandatoriesByLoggedUser?.at(-1)?.sbu.id,
        pageNumber: page - 1,
        pageSize: 2,
      }),
    { enabled: !!mandatoriesByLoggedUser, retry: false }
  );

  const mandatoryOptions = useMemo(() => {
    return mandatoriesByLoggedUser
      ?.map(({ id, year }) => ({
        key: id,
        text: year.split('-')[0],
        value: id,
      }))
      .reverse();
  }, [mandatoriesByLoggedUser]);

  useEffect(() => {
    if (mandatoryOptions) {
      setValue('mandatoryId', mandatoryOptions?.[0]?.value);
    }
  }, [mandatoryOptions, setValue]);

  const { message: welcomeMessage, isLoading: isLoadingWelcomeMessage } =
    useMessageByType({
      messageTypeName: 'WELCOME',
      lastMandatoryId: mandatoriesByLoggedUser?.at(-1)?.id,
    });

  function toggleFilterBar() {
    setShowFilterBar((prev) => !prev);
  }

  function handlePageChange(
    _: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    { activePage }: PaginationProps
  ) {
    setPage(activePage as number);
  }

  const renderMyTeam = useCallback(() => {
    if (isLoadingSubordinates) {
      return <LoaderCentered />;
    }

    if (!isLoadingSubordinates && !subordinates?.content.length) {
      return <Header content={t('pages.home.myTeam.NoDataFound')} />;
    }

    return subordinates?.content?.map(
      ({ id, name, jobTitle, userId, mandatoryId, sbuId }) => (
        <Link
          key={id}
          to={`/development/${mandatoryId}/${sbuId}`}
          state={userId}
        >
          <div className="myTeam__member">
            <span>
              <div className="myTeam__member--name">{name}</div>
              <div className="myTeam__member--jobtitle">{jobTitle}</div>
            </span>
            <Icon className="myTeam__member--btn" link name="caret right" />
          </div>
        </Link>
      )
    );
  }, [isLoadingSubordinates, subordinates?.content, t]);

  const onSubmit = useCallback((data: FilterProps) => {
    setQuery({ ...data });
  }, []);

  function handleClearClick() {
    reset({ mandatoryId: null });
    setQuery({});
  }

  return (
    <Container>
      <WelcomeBar
        message={welcomeMessage}
        isLoadingMessage={isLoadingWelcomeMessage}
        isEmployeeLoading={isAssociatedDataLoading}
        employee={associatedData}
      />

      <MyMandatories>
        <div className="myMandatories__header">
          <div className="myMandatories__header--title">
            <Icon name="graduation cap" />
            {t('pages.development.title')}
          </div>
        </div>

        <div className="myMandatories__list">
          {isLoadingmandatoriesByLoggedUser ? (
            <span
              style={{
                height: '100%',
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Loader active inline />
            </span>
          ) : (
            mandatoriesByLoggedUser
              ?.map(({ id, year, released, sbu }) => (
                <MandatoryCard
                  key={id}
                  mandatoryId={id}
                  year={year}
                  sbu={sbu}
                  released={released}
                />
              ))
              .reverse()
          )}
        </div>
      </MyMandatories>

      <MyTeam hasSubordinates={!!subordinates?.content.length}>
        <MyTeamHeader>
          <div className="myTeam__header--title">
            <Icon name="group" />
            {t('pages.development.myTeam')}
          </div>
          <ButtonGDY
            className="myTeam__header--filterBtn"
            onClick={toggleFilterBar}
            icon="filter"
            size="tiny"
          />
        </MyTeamHeader>

        <MyTeamContent>
          <div className="myTeam__list">{renderMyTeam()}</div>

          <div className="myTeam__pagination">
            <Pagination
              activePage={page}
              totalPages={subordinates?.totalPages ?? 0}
              onPageChange={handlePageChange}
              disabled={!subordinates?.content.length}
            />
          </div>
        </MyTeamContent>
      </MyTeam>

      <Filter
        marginTop={134}
        onApplyClick={handleSubmit(onSubmit)}
        onClearClick={handleClearClick}
        closeFilterBar={() => setShowFilterBar(false)}
        show={showFilterBar}
      >
        <FilterFormGroup>
          <ControllerFormDropdown
            controllerControl={control}
            controllerName="mandatoryId"
            label={t('pages.development.year')}
            options={mandatoryOptions}
          />
        </FilterFormGroup>
      </Filter>
    </Container>
  );
}

export { Home };
