import { useTranslation } from 'react-i18next';
import {
  Table as TableSemantic,
  Icon,
  Pagination,
  PaginationProps,
} from 'semantic-ui-react';

import { TableNoDataFound } from '../../../../components/TableNoDataFound';
import { MessagesResponseProps } from '../../../../types/messages';
import { TableBody } from '../TableBody';

interface ITableProps {
  handleFormModalOpen: (messageId: number) => void;
  handleDeleteModalOpen: (messageId: number) => void;
  messages?: MessagesResponseProps;
  page: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
}

function Table({
  handleFormModalOpen,
  handleDeleteModalOpen,
  messages,
  page,
  setPage,
}: ITableProps) {
  const { t } = useTranslation();
  function handlePageChange(
    _: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    { activePage }: PaginationProps
  ) {
    setPage(activePage as number);
  }

  return (
    <>
      <div className="table__scrollable">
        <TableSemantic className="table" unstackable>
          <TableSemantic.Header className="thead">
            <TableSemantic.Row className="tr">
              <TableSemantic.HeaderCell
                width={1}
                className="th idCol messages"
                textAlign="center"
              >
                {t('pages.messages.id')}
              </TableSemantic.HeaderCell>

              <TableSemantic.HeaderCell
                className="th sbuCol messages"
                textAlign="center"
              >
                {t('pages.messages.sbu')}
              </TableSemantic.HeaderCell>

              <TableSemantic.HeaderCell
                className="th sbuCol messages"
                textAlign="center"
              >
                {t('pages.messages.countryLocation')}
              </TableSemantic.HeaderCell>

              <TableSemantic.HeaderCell className="th title messages">
                {t('pages.messages.title')}
              </TableSemantic.HeaderCell>

              <TableSemantic.HeaderCell className="th fileName messages">
                {t('pages.messages.fileName')}
              </TableSemantic.HeaderCell>

              <TableSemantic.HeaderCell className="th type messages">
                {t('pages.messages.importedBy')}
              </TableSemantic.HeaderCell>

              <TableSemantic.HeaderCell className="th code messages">
                {t('pages.messages.code')}
              </TableSemantic.HeaderCell>

              <TableSemantic.HeaderCell className="th type messages">
                {t('pages.messages.type')}
              </TableSemantic.HeaderCell>

              <TableSemantic.HeaderCell className="th action messages">
                <Icon name="cog" size="large" />
              </TableSemantic.HeaderCell>
            </TableSemantic.Row>
          </TableSemantic.Header>
          <TableBody
            messages={messages?.content}
            handleFormModalOpen={handleFormModalOpen}
            handleDeleteModalOpen={handleDeleteModalOpen}
          />
        </TableSemantic>
      </div>
      <div className="table__pagination">
        {messages?.content.length ? (
          <Pagination
            page={page}
            activePage={page}
            totalPages={messages?.totalPages ?? 0}
            onPageChange={handlePageChange}
          />
        ) : (
          <TableNoDataFound />
        )}
      </div>
    </>
  );
}

export default Table;
