import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import { Dropdown, Icon } from 'semantic-ui-react';

import { useAuth } from '../../../hooks/useAuth';
import { useDownloadUserGuide } from '../../../hooks/useDownloadUserGuide';
import {
  Container,
  Logo,
  SidebarButton,
  ApplicationName,
  AppName,
  UserProfile,
  Profile,
} from './styles';

interface Props {
  handleShow: () => void;
}

function Header({ handleShow }: Props) {
  const { i18n, t } = useTranslation();
  const { user, signOut } = useAuth();
  const navigate = useNavigate();

  const { onDownloadUserQuide } = useDownloadUserGuide();

  const handleSignOut = () => {
    signOut();
    navigate('/auth');
  };

  const handleChangeLanguage = (value: string) => {
    i18n.changeLanguage(value);
    localStorage.setItem('@LANGUAGE', i18n.language);
  };

  return (
    <Container id="layout__header">
      <SidebarButton onClick={handleShow}>
        <Icon name="bars" />
      </SidebarButton>

      <ApplicationName>
        <Link to="/web">
          <Logo />
          <AppName>{t('application.name')}</AppName>
        </Link>
      </ApplicationName>

      <UserProfile>
        <Dropdown
          trigger={
            <Profile>
              <div>
                <span>
                  {user?.firstName} {user?.lastName}
                </span>
                <span>{user?.userId}</span>
              </div>

              <img
                src={
                  user?.photoBase64
                    ? `data:image/png;base64,${user?.photoBase64}`
                    : `https://ui-avatars.com/api/?rounded=true&background=random&color=random&name=${user?.firstName}${user?.lastName}`
                }
                alt="profile_photo"
              />
            </Profile>
          }
          icon={null}
          pointing="top right"
        >
          <Dropdown.Menu>
            <Dropdown.Item>
              <Dropdown
                trigger={
                  <span>
                    <Icon name="globe" /> {t('languages.label')}
                  </span>
                }
                pointing="right"
                options={[
                  {
                    key: 1,
                    value: 'pt-BR',
                    text: 'Português',
                    active: i18n.language === 'pt-BR',
                    flag: 'brazil',
                  },
                  {
                    key: 2,
                    value: 'en-US',
                    text: 'English',
                    active: i18n.language === 'en-US',
                    flag: 'united states',
                  },
                ]}
                onChange={(_, data) =>
                  handleChangeLanguage(data.value as string)
                }
              />
            </Dropdown.Item>

            <Dropdown.Item
              text={t('label.userGuides')}
              onClick={onDownloadUserQuide}
            />

            <Dropdown.Divider />

            <Dropdown.Item text={t('label.logout')} onClick={handleSignOut} />
          </Dropdown.Menu>
        </Dropdown>
      </UserProfile>
    </Container>
  );
}

export { Header };
