import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';

import { FileHelper } from '../helpers/file';
import i18n from '../locales';
import { downloadUserGuides } from '../services/downloadUserGuides';

export const useDownloadUserGuide = () => {
  const { t } = useTranslation();

  const downloadUserGuide = useMutation({
    mutationFn: async () => {
      return toast.promise(downloadUserGuides({ lang: i18n.language as any }), {
        pending: t('toasts.fileDownloading'),
        success: t('toasts.fileDownloadSuccess'),
        error: t('toasts.fileDowloadError'),
      });
    },
    onSuccess: (data) => {
      FileHelper.downloadPdfFromBase64(data, 'USER_GUIDES_TDS');
    },
  });

  const onDownloadUserQuide = () => downloadUserGuide.mutateAsync();
  const { isLoading } = downloadUserGuide;

  return {
    onDownloadUserQuide,
    isLoading,
  };
};
