import { Icon as FluentIcon } from '@fluentui/react/lib/Icon';
import { useEffect } from 'react';
import {
  Control,
  FormState,
  UseFormHandleSubmit,
  UseFormSetValue,
  useWatch,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { Form, Input, ModalContent } from 'semantic-ui-react';

import { ControllerFormDropdown } from '../../../../components/Form/ControllerFormDropdown';
import { ControllerFormField } from '../../../../components/Form/ControllerFormField';
import { useMandatoryWithTrainingIdOptions } from '../../../../hooks/useMandatoryWithTrainingIdOptions';
import { useSbuOptions } from '../../../../hooks/useSbuOptions';
import icon from '../../../../mocks/icon.json';
import sbus from '../../../../mocks/sbu.json';
import { getTrainingTypeList } from '../../../../services/training';
import { TrainingDataForm } from '../../../../types/training';

interface IProps {
  control: Control<TrainingDataForm, object>;
  formRef?: React.RefObject<HTMLFormElement>;
  setValue: UseFormSetValue<TrainingDataForm>;
  onSubmit: (data: TrainingDataForm) => Promise<void>;
  handleSubmit: UseFormHandleSubmit<TrainingDataForm>;
  formState: FormState<TrainingDataForm>;
}

function sortItems(a: any, b: any) {
  if (a.text < b.text) {
    return -1;
  }
  if (a.text > b.text) {
    return 1;
  }
  return 0;
}

const FIELD_REQUIRED_MESSAGE = 'components.required';

function FormContent({
  control,
  formRef,
  handleSubmit,
  onSubmit,
  setValue,
  formState,
}: IProps) {
  const { t } = useTranslation();
  const watchRegion = useWatch({ control, name: 'region' });
  const watchSbuId = useWatch({ control, name: 'sbuId' });
  const watchTrainingId = useWatch({ control, name: 'id' });

  const { sbusOptions } = useSbuOptions(watchRegion);

  const { mandatoryOptions } = useMandatoryWithTrainingIdOptions({
    watchRegion,
    watchSbuId,
    watchTrainingId,
  });

  useEffect(() => {
    if (mandatoryOptions && !watchTrainingId) {
      setValue('mandatoryId', mandatoryOptions?.[0]?.key);
    }
  }, [watchSbuId, mandatoryOptions, setValue, watchTrainingId]);

  const { data: trainingsTypeOptions } = useQuery('trainings', async () => {
    const data = await getTrainingTypeList();

    return data?.map(({ id, name }) => ({
      key: id,
      text: name,
      value: id,
    }));
  });

  const iconOptions = icon
    .map((item) => ({
      ...item,
      icon: <FluentIcon iconName={item?.value} className="left floated" />,
    }))
    .sort(sortItems);

  return (
    <ModalContent>
      <Form ref={formRef} onSubmit={handleSubmit(onSubmit)}>
        <ControllerFormDropdown
          controllerControl={control}
          controllerName="region"
          label={t('pages.training.sbu')}
          options={sbus}
          required
          error={formState.errors.region && t(FIELD_REQUIRED_MESSAGE)}
          fluid
        />

        <ControllerFormDropdown
          controllerControl={control}
          controllerName="sbuId"
          label={t('pages.training.countryLocation')}
          options={sbusOptions}
          required={!!watchRegion}
          error={formState.errors.sbuId && t(FIELD_REQUIRED_MESSAGE)}
          disabled={!watchRegion}
          fluid
        />

        <Form.Group widths="equal">
          <ControllerFormField
            controllerControl={control}
            controllerName="code"
            control={Input}
            label={t('pages.training.code')}
            required
            error={formState.errors.code && t(FIELD_REQUIRED_MESSAGE)}
            isNotUpperCase
          />

          <ControllerFormDropdown
            controllerControl={control}
            controllerName="mandatoryId"
            label={t('pages.training.year')}
            options={mandatoryOptions}
            required={!!watchSbuId}
            disabled
            error={formState.errors.mandatoryId && t(FIELD_REQUIRED_MESSAGE)}
            fluid
          />
        </Form.Group>

        <Form.Group widths="equal">
          <ControllerFormDropdown
            controllerControl={control}
            controllerName="typeId"
            label={t('pages.training.category')}
            options={trainingsTypeOptions}
            required
            error={formState.errors.typeId && t(FIELD_REQUIRED_MESSAGE)}
            fluid
          />
          <ControllerFormDropdown
            controllerControl={control}
            controllerName="icon"
            label={t('pages.training.icon')}
            options={iconOptions}
            required
            error={formState.errors.icon && t(FIELD_REQUIRED_MESSAGE)}
            fluid
          />
        </Form.Group>

        <ControllerFormField
          controllerControl={control}
          controllerName="shortDescription"
          control={Input}
          label={t('pages.training.shortDescription')}
          required
          error={formState.errors.shortDescription && t(FIELD_REQUIRED_MESSAGE)}
          isNotUpperCase
        />

        <ControllerFormField
          controllerControl={control}
          controllerName="description"
          control={Input}
          label={t('pages.training.description')}
          required
          error={formState.errors.description && t(FIELD_REQUIRED_MESSAGE)}
          isNotUpperCase
        />
      </Form>
    </ModalContent>
  );
}

export { FormContent };
