import { useState } from 'react';
import { Outlet } from 'react-router-dom';

import { useAuth } from '../../hooks/useAuth';
import { SplashScreen } from '../SplashScreen';
import { Content } from './Content';
import { Header } from './Header';
import { Sidebar } from './Sidebar';
import { Container } from './styles';

function Layout() {
  const [openSidebar, setOpenSidebar] = useState<boolean>(false);
  const { isSplashLoading } = useAuth();

  const handleShow = () => setOpenSidebar((oldValue) => !oldValue);

  return (
    <>
      {isSplashLoading && <SplashScreen />}
      <Container id="layout">
        <Header handleShow={handleShow} />

        <Sidebar
          show={openSidebar}
          setShow={setOpenSidebar}
          handleShow={handleShow}
        />

        <Content>
          <Outlet />
        </Content>
      </Container>
    </>
  );
}

export { Layout };
