import axios from 'axios';

import { BackgroundImage } from '../types/bgImages';
import { ENV } from '../types/env';

export async function getBackgroundImages(): Promise<BackgroundImage> {
  const response = await axios.get(ENV.REACT_APP_BACKGROUND_IMAGES);

  return response.data;
}
