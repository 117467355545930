import moment from 'moment';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import {
  Button,
  Checkbox,
  Confirm,
  Icon,
  Label,
  Table as TableSemantic,
} from 'semantic-ui-react';

import {
  downloadExportDataRequest,
  findLogRequest,
  freezeMandatoryRequest,
  unfreezeMandatoryRequest,
} from '../../../../services/imports';
import {
  EStatusId,
  Import,
  LogResponse,
  StatusColor,
} from '../../../../types/import';
import { LogViewModal } from '../LogViewModal';

interface TableBodyProps {
  imports: Import[];
}

type Loading = {
  type: 'log' | 'download';
  loading: boolean;
  id: number;
};

function TableBody({ imports }: TableBodyProps) {
  const { t } = useTranslation();
  const [loading, setLoading] = useState<Loading>({
    type: 'log',
    loading: false,
    id: 0,
  });
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [logs, setLogs] = useState<LogResponse[]>([]);
  const [isConfirmFreeze, setIsConfirmFreeze] = useState<boolean>(false);

  const queryClient = useQueryClient();

  const handleLogView = useMutation(
    async (id: number) => {
      setLoading({ type: 'log', loading: true, id });
      const response = await findLogRequest(id);
      setLogs(response);
      setLoading({ type: 'log', loading: false, id: 0 });
      return response;
    },
    {
      onSuccess: () => {
        setIsOpen(true);
      },
      onError: () => {
        toast.error(t('components.toast.error.get'));
      },
    }
  );

  const handleFreeze = useMutation(
    async (id: number) => {
      await freezeMandatoryRequest(id);
      setIsConfirmFreeze((oldValue) => !oldValue);
    },
    {
      onSuccess: () => {
        toast.success(t('components.toast.success.edit'));
        queryClient.invalidateQueries('imports');
      },
      onError: () => {
        toast.error(t('components.toast.error.edit'));
      },
    }
  );

  const handleUnfreeze = useMutation(
    async (id: number) => {
      await unfreezeMandatoryRequest(id);
      setIsConfirmFreeze((oldValue) => !oldValue);
    },
    {
      onSuccess: () => {
        toast.success(t('components.toast.success.edit'));
        queryClient.invalidateQueries('imports');
      },
      onError: () => {
        toast.error(t('components.toast.error.edit'));
      },
    }
  );

  const handleDownloadExportData = useMutation(
    async (id: number) => {
      setLoading({ type: 'download', loading: true, id });
      const response = await downloadExportDataRequest(id);
      setLoading({ type: 'download', loading: false, id: 0 });
      return response;
    },
    {
      onSuccess: (data: Blob) => {
        const url = window.URL.createObjectURL(data);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'EXPORT_DATA.xlsx');
        link.click();
      },
      onError: () => {
        toast.error(t('components.toast.error.get'));
      },
    }
  );

  return (
    <TableSemantic.Body className="tbody">
      {imports?.map((item, index) => {
        const importedByName = `${item.importedBy.split('_')[0]} ${
          item.importedBy.split('_')[1]
        }`;
        const importedByUserId = item.importedBy.split('_')[2];

        return (
          <TableSemantic.Row key={item.id} className="tr noRowspan">
            <TableSemantic.Cell className="td" textAlign="center">
              {moment(item.mandatory.year).format('YYYY')}
            </TableSemantic.Cell>

            <TableSemantic.Cell className="td" textAlign="center">
              {item.sbu.country}
            </TableSemantic.Cell>

            <TableSemantic.Cell className="td" textAlign="center">
              {moment(item.createdAt).format('DD/MM/YYYY')}
            </TableSemantic.Cell>

            <TableSemantic.Cell className="td">
              {`${importedByName} (${importedByUserId})`}
            </TableSemantic.Cell>

            <TableSemantic.Cell className="td">
              <Label color={StatusColor[item.status.name]}>
                {item.status.id === EStatusId.Saving && (
                  <Icon name="sync" loading />
                )}
                {item.status.name}
              </Label>
            </TableSemantic.Cell>

            <TableSemantic.Cell className="td" textAlign="center">
              <Button
                icon="file archive"
                color="grey"
                size="mini"
                className="filter-list profile edit"
                loading={
                  loading.type === 'log' &&
                  loading.id === item.id &&
                  loading.loading
                }
                onClick={() => handleLogView.mutateAsync(item.id)}
              />
            </TableSemantic.Cell>

            <TableSemantic.Cell className="td" textAlign="center">
              <Button
                icon="file"
                color="grey"
                size="mini"
                className="filter-list profile edit"
                loading={
                  loading.type === 'download' &&
                  loading.id === item.id &&
                  loading.loading
                }
                onClick={() => handleDownloadExportData.mutateAsync(item.id)}
              />
            </TableSemantic.Cell>

            <TableSemantic.Cell className="td" textAlign="center">
              <Checkbox
                toggle
                style={{ marginLeft: 10 }}
                checked={
                  index !== 0
                    ? false
                    : item.mandatory.released &&
                      item.status.id === EStatusId.Success
                }
                disabled={index !== 0 || item.status.id !== EStatusId.Success}
                onChange={() => setIsConfirmFreeze((oldValue) => !oldValue)}
              />
            </TableSemantic.Cell>
          </TableSemantic.Row>
        );
      })}

      {logs.length > 0 && (
        <LogViewModal
          data={logs}
          open={isOpen}
          size="small"
          onClose={() => setIsOpen(false)}
          closeIcon
        />
      )}

      <Confirm
        header={t('pages.uploads.confirm.title')}
        content={t(
          imports[0].mandatory.released
            ? 'pages.uploads.confirm.confirmUnFreeze'
            : 'pages.uploads.confirm.confirmFreeze'
        )}
        cancelButton={{
          content: t('components.buttons.cancelButton'),
        }}
        confirmButton={{
          content: t('components.buttons.confirmButton'),
          positive: !imports[0].mandatory.released,
          negative: !!imports[0].mandatory.released,
        }}
        open={isConfirmFreeze}
        onCancel={() => setIsConfirmFreeze((oldValue) => !oldValue)}
        onConfirm={() =>
          imports[0].mandatory.released
            ? handleUnfreeze.mutateAsync(imports[0].id)
            : handleFreeze.mutateAsync(imports[0].id)
        }
      />
    </TableSemantic.Body>
  );
}

export { TableBody };
