import { useQuery } from 'react-query';

import { getTrainingTypeList } from '../services/training';

export function useTrainingTypeOptions() {
  const { data: trainingTypeOptions } = useQuery(
    'TRAINING_TYPE_ID_OPTIONS',
    async () => {
      const data = await getTrainingTypeList();
      return data?.map(({ id, name }) => ({
        key: id,
        text: name,
        value: id,
      }));
    }
  );

  return {
    trainingTypeOptions,
  };
}
