import { ENV } from '../types/env';

export const STRUCTURES_QUERYKEY = 'structures';
export const TRAININGS_QUERYKEY = 'trainings';
export const MESSAGES_QUERYKEY = 'messages';
export const PROFILES_QUERYKEY = 'profiles';

export const FIELD_REQUIRED_MESSAGE = 'components.required';

// GOOGLE ANALYTICS
export const REACT_APP_TAG_GOOGLE = `${ENV.REACT_APP_TAG_GOOGLE}`;
