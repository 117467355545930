import {
  Chart as ChartJS,
  ArcElement,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Doughnut } from 'react-chartjs-2';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';

import { MandatoryDetailsResponseProps } from '../../../../types/mandatoryDetails';
import { Container, ChartBox } from './styles';

ChartJS.register(
  ArcElement,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Legend,
  Tooltip,
  ChartDataLabels
);

interface IProps {
  trainings?: MandatoryDetailsResponseProps;
}

function DoughnutChart({ trainings }: IProps) {
  const { t } = useTranslation();
  const { palette } = useTheme();

  const dataMock = {
    labels: trainings?.data?.map(({ trainingTypeName }) => trainingTypeName),
    datasets: [
      {
        label: t('pages.development.percentualHours'),
        data: trainings?.data?.map(
          ({ percentage }) => Number(percentage)?.toFixed(2) ?? undefined
        ),
        backgroundColor: [
          palette.CHARTS.PINK,
          palette.CHARTS.BLUE,
          palette.CHARTS.YELLOW,
          palette.CHARTS.GREEN,
          palette.CHARTS.PURPLE,
          palette.CHARTS.ORANGE,
        ],
        borderWidth: 0,
      },
    ],
  };

  return (
    <Container>
      <ChartBox>
        <Doughnut
          data={dataMock}
          options={{
            indexAxis: 'y',
            responsive: true,
            plugins: {
              datalabels: {
                formatter: (value: number | string) => {
                  if (value === '0.00' || value === 0 || value === 'NaN') {
                    return null;
                  }

                  return `${value}%`;
                },
                anchor: 'center',
                color: palette.TEXT.CONTRAST_TWO,
                labels: {
                  title: {
                    font: {
                      weight: 'bold',
                    },
                  },
                },
              },
              legend: {
                display: true,
                position: 'bottom',
                labels: {
                  color: palette.TEXT.CONTRAST_TWO,
                },
              },
            },
          }}
        />
      </ChartBox>
    </Container>
  );
}

export { DoughnutChart };
