import { useCallback, useState } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { Input, Loader } from 'semantic-ui-react';

import { ControllerFormDropdown } from '../../components/Form/ControllerFormDropdown';
import { ControllerFormField } from '../../components/Form/ControllerFormField';
import { FilterFormGroup } from '../../components/Form/FilterFormGroup';
import { Toolbar } from '../../components/Toolbar';
import { useMandatoryOptions } from '../../hooks/useMandatoryOptions';
import { useSbuOptions } from '../../hooks/useSbuOptions';
import REGIONS from '../../mocks/sbu.json';
import { getEmployeesList } from '../../services/employees';
import { IEmployeesFilterProps } from '../../types/filters';
import { Table } from './components/Table';
import { Container } from './styles';

const THIS_FIELD_REQUIRED = 'This field is required';

export function Employees() {
  const { t } = useTranslation();
  const {
    control,
    formState: { errors },
    reset,
    setValue,
    handleSubmit,
  } = useForm<IEmployeesFilterProps>();

  const [page, setPage] = useState(1);
  const [query, setQuery] = useState<IEmployeesFilterProps>({
    sbuId: undefined,
    userId: undefined,
    userName: undefined,
    jobTitle: undefined,
    businessLine: undefined,
    mandatoryId: undefined,
  });

  const { data: employees, isLoading } = useQuery(
    ['employees', { page, ...query }],
    async () =>
      getEmployeesList({ pageNumber: page - 1, pageSize: 10, ...query }),
    {
      refetchOnWindowFocus: false,
      enabled: !!query.sbuId && !!query.mandatoryId,
    }
  );

  const watchRegion = useWatch({ control, name: 'region' });
  const watchSBU = useWatch({ control, name: 'sbuId' });

  const { sbusOptions } = useSbuOptions(watchRegion as string);

  const { mandatoryOptions } = useMandatoryOptions({
    watchSBU: Number(watchSBU),
    watchRegion: String(watchRegion),
    setValue,
  });

  const onSubmit = useCallback((data: IEmployeesFilterProps) => {
    setQuery({ ...data });
  }, []);

  function handleClearFilters() {
    reset({
      region: null,
      sbuId: null,
      mandatoryId: null,
      userId: '',
      userName: '',
      jobTitle: '',
      businessLine: '',
    });
    setQuery({});
  }

  return (
    <Container>
      <div className="page__title">{t('menu.employees')}</div>
      <Toolbar
        onClearClick={handleClearFilters}
        onAddClick={() => undefined}
        onApplyClick={handleSubmit(onSubmit)}
        disableNewButton
      >
        <FilterFormGroup>
          <ControllerFormDropdown
            controllerControl={control}
            controllerName="region"
            label={t('pages.employees.sbu')}
            options={REGIONS}
            required
            error={errors.region && THIS_FIELD_REQUIRED}
            fluid
          />
          <ControllerFormDropdown
            controllerControl={control}
            controllerName="sbuId"
            label={t('pages.employees.countryLocation')}
            options={sbusOptions}
            required={!!watchRegion}
            error={errors.sbuId && THIS_FIELD_REQUIRED}
            disabled={!watchRegion}
            fluid
          />
          <ControllerFormDropdown
            controllerControl={control}
            controllerName="mandatoryId"
            label={t('pages.employees.year')}
            options={mandatoryOptions}
            required={!!watchSBU}
            error={errors.mandatoryId && THIS_FIELD_REQUIRED}
            disabled={!watchRegion || !watchSBU}
            fluid
          />
        </FilterFormGroup>

        <FilterFormGroup>
          <ControllerFormField
            controllerControl={control}
            controllerName="userId"
            control={Input}
            label={t('pages.employees.userId')}
            fluid
          />
          <ControllerFormField
            controllerControl={control}
            controllerName="userName"
            control={Input}
            label={t('pages.employees.name')}
            fluid
          />
          <ControllerFormField
            controllerControl={control}
            controllerName="jobTitle"
            control={Input}
            label={t('pages.employees.jobTitle')}
            fluid
          />
          <ControllerFormField
            controllerControl={control}
            controllerName="businessLine"
            control={Input}
            label={t('pages.employees.costCenter')}
            fluid
          />
        </FilterFormGroup>
      </Toolbar>

      {isLoading ? (
        <Loader active />
      ) : (
        <Table employees={employees} page={page} setPage={setPage} />
      )}
    </Container>
  );
}
