import axios, { AxiosError } from 'axios';

import { ENV } from '../types/env';
import { ErrorResponse } from '../types/error.types';
import cookies from '../utils/cookies';

const api = axios.create({
  baseURL: ENV.BASE_API_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

api.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error: AxiosError<ErrorResponse>) => {
    const token = cookies.getCookie('@TOKEN');

    if (Number(error.response?.data?.businessCode) === 100 && token) {
      api.defaults.headers.common.jwt = token;
    }

    if (
      Number(error.response?.data.businessCode) === 105 ||
      Number(error.response?.data.businessCode) === 102
    ) {
      delete api.defaults.headers.common.jwt;
      cookies.removeCookie('@TOKEN');
      window.location.href = `${window.location.origin}/auth`;
    }

    return Promise.reject(error);
  }
);

export default api;
