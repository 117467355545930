/* eslint-disable react/no-danger */
import { useTranslation } from 'react-i18next';
import { Divider, Icon } from 'semantic-ui-react';

import { LoaderCentered } from '../../../../../components/LoaderCentered';
import { MandatoryDetailsResponseProps } from '../../../../../types/mandatoryDetails';
import { MessagesResponseProps } from '../../../../../types/messages';
import { DoughnutChart } from '../../DoughnutChart';
import {
  ContactUs,
  Container,
  TrainingChart,
  TrainingDescription,
} from './styles';

interface IProps {
  trainings?: MandatoryDetailsResponseProps;
  messages?: MessagesResponseProps;
  isLoadingMessages?: boolean;
  qrCode?: string;
  isLoadingQrCode?: boolean;
}

export function PageTwo({
  trainings,
  messages,
  isLoadingMessages,
  qrCode,
  isLoadingQrCode,
}: IProps) {
  const { t } = useTranslation();

  function renderTrainingList() {
    if (isLoadingMessages) {
      return <LoaderCentered />;
    }

    if (messages) {
      return messages?.content?.map(({ type, content }) => (
        <tr key={type.name} className="trainingDescription__row">
          <td>
            <span className="trainingDescription__trainingType">
              {type.name}
            </span>
          </td>

          <td>
            <div
              className="trainingDescription__training"
              dangerouslySetInnerHTML={{ __html: content }}
            />
          </td>
        </tr>
      ));
    }

    return undefined;
  }

  return (
    <Container>
      <TrainingChart>
        <div className="trainingChart__header">
          <div className="trainingChart__header--title">
            Total Corporate Development hours
          </div>

          <div className="trainingChart__header--hours">
            {trainings?.total.toFixed(2) ?? 0}h
          </div>
        </div>

        <Divider />

        <div className="trainingChart__list">
          {trainings?.data?.map(({ trainingTypeName, total }) => (
            <div key={trainingTypeName} className="trainingChart__listItem">
              <div className="trainingChart__listItem--label">
                {trainingTypeName}
              </div>
              <div className="trainingChart__listItem--hours">
                {total?.toFixed(2) ?? 0}h
              </div>
            </div>
          ))}
        </div>

        <div className="trainingChart--chart">
          <DoughnutChart trainings={trainings} />
        </div>
      </TrainingChart>

      <TrainingDescription>
        <tbody>{renderTrainingList()}</tbody>
      </TrainingDescription>

      <ContactUs>
        <div className="contactUs__information">
          <Icon
            className="contactUs__information--icon"
            name="users"
            size="big"
          />
          <div className="contactUs__information--title">
            {t('pages.development.reportPages.pageTwo.contactUs.contactUs')}
          </div>
        </div>

        {!isLoadingQrCode && !!qrCode && (
          <div
            className="contactUs__qrCode"
            dangerouslySetInnerHTML={{ __html: qrCode }}
          />
        )}
      </ContactUs>
    </Container>
  );
}
