import styled from 'styled-components';

interface IProps {
  isOpen: boolean;
}

export const Container = styled.div`
  transition: 0.2s all ease;
  width: 100%;
`;

export const GridCardHeader = styled.div`
  background-color: #00000030;
  min-height: 35px;
  font-size: 1rem;
  color: #ffffff;
  font-weight: bold;
  padding: 8px 10px;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: nowrap;
  gap: 20px;

  cursor: pointer;

  @media print {
    i {
      display: none;
    }
  }

  span {
    white-space: nowrap;

    .gridCard__header--title {
      white-space: normal;
    }
  }

  .gridCard--collapse {
    cursor: pointer;
  }
`;

export const GridCardContent = styled.div<IProps>`
  font-weight: bold;
  height: ${({ isOpen }) => (isOpen ? 'auto' : 0)};

  transition: all ease 0.2s;
  overflow-y: hidden;
`;

export const GridCardItem = styled.div<IProps>`
  color: #ffffff;
  padding: ${({ isOpen }) => (isOpen ? '10px' : 0)} 10px;
  padding-left: 48px;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 4px;

  cursor: pointer;

  &:hover {
    background-color: #ffffff20;
  }

  .gridCardItem__title {
    width: 80%;
    display: flex;
    align-items: center;
    gap: 1rem;

    .gridCardItem__title--text {
      flex-wrap: nowrap;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .gridCardItem__hours {
  }
`;
