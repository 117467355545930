import { useTranslation } from 'react-i18next';
import { IoMdClose } from 'react-icons/io';
import { Button, Form } from 'semantic-ui-react';
import { useTheme } from 'styled-components';

import wingfootLogoImg from '../../assets/wingfoot-logo-yellow.png';
import { ButtonGDY } from '../Form/ButtonGDY';
import { Container } from './styles';

interface IFilterBarProps {
  children: React.ReactNode;
  show: boolean;
  marginTop?: number;
  closeFilterBar(): void;
  onApplyClick(): void;
  onClearClick(): void;
}

function Filter({
  children,
  closeFilterBar,
  show,
  marginTop,
  onApplyClick,
  onClearClick,
}: IFilterBarProps) {
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <Container show={show} top={marginTop}>
      <Form>
        <div className="filter__header">
          <img
            className="filter__logo"
            src={wingfootLogoImg}
            alt="Wing foot Logo"
          />
          <div className="filter__title">{t('components.filter')}</div>
          <div className="filter__close">
            <IoMdClose onClick={closeFilterBar} fontSize="2em" />
          </div>
        </div>

        <div className="filter__body">{children}</div>
        <div className="filter__submit">
          <Button
            style={{
              borderWidth: 1,
              borderStyle: 'solid',
              borderColor: theme.palette.GENERAL.PRIMARY,
              backgroundColor: theme.palette.BACKGROUND.DEFAULT,
              color: theme.palette.GENERAL.PRIMARY,
              marginBottom: '10px',
            }}
            width="1"
            content={t('components.buttons.clearButton')}
            onClick={onClearClick}
            fluid
          />
        </div>

        <div className="filter__submit">
          <ButtonGDY
            width="1"
            content={t('components.buttons.applyButton')}
            onClick={() => {
              onApplyClick();
              closeFilterBar();
            }}
            fluid
          />
        </div>
      </Form>
    </Container>
  );
}

export { Filter };
