import { RefObject, useEffect } from 'react';

import useMediaQuery from './useMediaQuery';

export default function useOnClickOutside(
  ref: RefObject<any>,
  handler: () => void
) {
  const { isWindowSizeChange } = useMediaQuery(460);

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        !isWindowSizeChange &&
        ref.current &&
        !ref.current?.contains(event.target)
      ) {
        handler();
      }
    }

    document.addEventListener('mousedown', handleClickOutside);

    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [ref, isWindowSizeChange, handler]);
}
