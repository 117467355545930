import { format } from 'date-fns';
import { t } from 'i18next';

const isDate = (value: any) => {
  return value instanceof Date;
};

const parse = (date: any) => {
  if (isDate(date)) {
    return date;
  }
  return new Date(date);
};

const dateFormat = (date?: string, layout?: string) => {
  if (!date) return '';
  const newValue = date.replaceAll('-', '/');
  return date
    ? format(parse(newValue), layout ?? t('commons.date.format'))
    : '';
};

export const DateHelper = {
  dateFormat,
};
