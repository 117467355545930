/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable import/no-cycle */
import { useEffect, useMemo, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { Link } from 'react-router-dom';
import { Icon, SemanticICONS, Table } from 'semantic-ui-react';

import { SubTable } from '../..';
import {
  getListReport,
  getReportTotalHoursAllSubordinates,
  getReportTotalHoursEmployees,
} from '../../../../../../services/report';
import { IReportFilterParamsProps } from '../../../../../../types/filters';
import { Report } from '../../../../../../types/report';

interface IProps {
  query: IReportFilterParamsProps;
  report: Report;
}

export function SubTableRow({ query, report }: IProps) {
  const [isExpandedSubRow, setIsExpandedSubRow] = useState(false);
  const [totalGeneralArea, setTotalGeneralArea] = useState<number>();
  const [totalImmediate, setTotalImmediate] = useState<number>();

  function toggleExpandedSubRow() {
    setIsExpandedSubRow((prev) => !prev);
  }

  const { data: reports, isLoading: isLoadingReports } = useQuery(
    [report, isExpandedSubRow],
    async () =>
      getListReport({
        mandatoryId: report.mandatory.id,
        sbuId: report.mandatory.sbu.id,
        userId: report.employee.za,
      }),
    {
      enabled: isExpandedSubRow,
    }
  );

  const handleTotalImmediate = useMutation({
    mutationFn: async () =>
      getReportTotalHoursEmployees({
        mandatoryId: report.mandatory.id,
        sbuId: report.mandatory.sbu.id,
        managerUserId: report.employee.za,
      }),
    onSuccess: (data) => {
      setTotalImmediate(data);
    },
    onError: () => {
      setTotalImmediate(undefined);
    },
  });

  const handleTotalGeneralArea = useMutation({
    mutationFn: async () =>
      getReportTotalHoursAllSubordinates({
        mandatoryId: report.mandatory.id,
        sbuId: report.mandatory.sbu.id,
        managerUserId: report.employee.za,
      }),
    onSuccess: (data) => {
      setTotalGeneralArea(data);
    },
    onError: () => {
      setTotalGeneralArea(undefined);
    },
  });

  useEffect(() => {
    const init = async () => {
      const promises = [
        handleTotalImmediate.mutateAsync(),
        handleTotalGeneralArea.mutateAsync(),
      ];

      Promise.all(promises);
    };

    init();
  }, []);

  const iconName = useMemo((): SemanticICONS => {
    if (isLoadingReports) {
      return 'sync';
    }

    if (reports?.content.length === 0) {
      return 'dont';
    }

    return `chevron ${isExpandedSubRow ? 'down' : 'right'}`;
  }, [isExpandedSubRow, isLoadingReports, reports?.content.length]);

  return (
    <Table.Body className="tbody" key={report.employee.id}>
      <Table.Row className="tr">
        <Table.Cell
          className="td collapse_btn"
          content={
            <Icon
              disabled={reports?.content.length === 0}
              name={iconName}
              link
              circular
              basic
              onClick={() => toggleExpandedSubRow()}
              loading={isLoadingReports}
            />
          }
        />

        <Table.Cell
          className="td report_employee_name"
          content={
            <Link
              className="linkUnderlined"
              to={`/development/${report.mandatory.id}/${report.mandatory.sbu.id}`}
              state={report.employee?.za}
            >
              {report.employee?.name}
            </Link>
          }
        />

        {report?.values?.map(({ trainingType, value }) => (
          <Table.Cell
            key={trainingType}
            className="td"
            content={value}
            textAlign="center"
          />
        ))}

        <Table.Cell className="td" content={report.total} textAlign="center" />

        <Table.Cell className="td" textAlign="center">
          {totalImmediate ?? (
            <Icon
              name={`${handleTotalImmediate.isLoading ? 'sync' : 'search'}`}
              link
              circular
              basic
              onClick={() => handleTotalImmediate.mutateAsync()}
              loading={handleTotalImmediate.isLoading}
            />
          )}
        </Table.Cell>

        <Table.Cell className="td" textAlign="center">
          {totalGeneralArea ?? (
            <Icon
              name={`${handleTotalGeneralArea.isLoading ? 'sync' : 'search'}`}
              link
              circular
              basic
              onClick={() => handleTotalGeneralArea.mutateAsync()}
              loading={handleTotalGeneralArea.isLoading}
            />
          )}
        </Table.Cell>
      </Table.Row>

      {reports &&
        reports?.content.length > 0 &&
        isExpandedSubRow &&
        !isLoadingReports && (
          <SubTable query={query} reports={reports?.content} />
        )}
    </Table.Body>
  );
}
