import { useTranslation } from 'react-i18next';
import {
  Table as TableSemantic,
  Pagination,
  PaginationProps,
} from 'semantic-ui-react';

import { TableNoDataFound } from '../../../../components/TableNoDataFound';
import { IReportFilterParamsProps } from '../../../../types/filters';
import { ReportResponseDataProps } from '../../../../types/report';
import { TableBody } from '../TableBody';

interface ITableProps {
  reports?: ReportResponseDataProps;
  page: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  query: IReportFilterParamsProps;
}

function Table({ reports, page, setPage, query }: ITableProps) {
  const { t } = useTranslation();
  function handlePageChange(
    _: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    { activePage }: PaginationProps
  ) {
    setPage(activePage as number);
  }

  return (
    <>
      <div className="table__scrollable">
        <TableSemantic className="table" unstackable>
          <TableSemantic.Header className="thead">
            <TableSemantic.Row className="tr">
              <TableSemantic.HeaderCell className="th" />

              <TableSemantic.HeaderCell
                className="th idColTr idCol"
                scope="col"
                content={t('pages.reports.id')}
                textAlign="center"
              />

              <TableSemantic.HeaderCell
                className="th iconCol"
                scope="col"
                content={t('pages.reports.year')}
                textAlign="center"
              />

              <TableSemantic.HeaderCell
                className="th iconCol"
                scope="col"
                content={t('pages.reports.country')}
                textAlign="center"
              />

              <TableSemantic.HeaderCell
                className="th iconCol"
                scope="col"
                content={t('pages.reports.name')}
              />

              {reports?.content?.[0]?.values?.map(({ trainingType }) => (
                <TableSemantic.HeaderCell
                  key={trainingType}
                  className="th iconCol"
                  scope="col"
                  textAlign="center"
                  content={trainingType}
                />
              ))}

              <TableSemantic.HeaderCell
                className="th iconCol"
                scope="col"
                content={t('pages.reports.associateTotal')}
                textAlign="center"
              />

              <TableSemantic.HeaderCell
                className="th iconCol"
                scope="col"
                content={t('pages.reports.totalImmediate')}
                textAlign="center"
              />

              <TableSemantic.HeaderCell
                className="th iconCol"
                scope="col"
                content={t('pages.reports.generalTotalArea')}
                textAlign="center"
              />
            </TableSemantic.Row>
          </TableSemantic.Header>

          <TableBody reports={reports?.content} query={query} />
        </TableSemantic>
      </div>

      <div className="table__pagination">
        {reports?.content.length ? (
          <Pagination
            page={page}
            activePage={page}
            totalPages={reports?.totalPages ?? 0}
            onPageChange={handlePageChange}
          />
        ) : (
          <TableNoDataFound />
        )}
      </div>
    </>
  );
}

export { Table };
