import { yupResolver } from '@hookform/resolvers/yup';
import { useRef, useCallback, useState, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';
import { Modal, ModalProps } from 'semantic-ui-react';
import * as yup from 'yup';

import { ButtonGDY } from '../../../../components/Form/ButtonGDY';
import { createImportRequest } from '../../../../services/imports';
import { queryClient } from '../../../../services/queryClient';
import { FIELD_REQUIRED_MESSAGE } from '../../../../settings/constants';
import { ImportFormData } from '../../../../types/import';
import { FormContent } from '../FormContent';

type UploadFormModalProps = ModalProps & {
  isOpen: boolean;
  initialValues?: ImportFormData;
  onRequestClose: () => void;
};

function getInitialValues(initialValues: ImportFormData): ImportFormData {
  return {
    ...initialValues,
  };
}

const currYear = new Date().getFullYear();
const minYear = currYear - 10;
const maxYear = currYear + 1;
const listYears = Object.keys([...Array(maxYear - minYear)])
  .map((i) => minYear + Number(i))
  .join('|');

const mandatoryYearRegex = new RegExp(
  `^(${minYear}|${minYear + 1}|${listYears})$`
);

function UploadFormModal({
  isOpen,
  initialValues,
  onRequestClose,
  ...rest
}: UploadFormModalProps) {
  const { t } = useTranslation();
  const formRef = useRef<HTMLFormElement>(null);
  const [isMandatoryYearInputField, setIsMandatoryYearInputField] =
    useState(false);

  const schema = useMemo(() => {
    return yup
      .object<ImportFormData>()
      .shape({
        region: yup.string().required(),
        sbuId: yup.number().required(),
        mandatoryYear: isMandatoryYearInputField
          ? yup
              .string()
              .matches(mandatoryYearRegex, {
                excludeEmptyString: true,
                message: `${t(
                  'pages.uploads.form.required.year.validYears'
                )} ${minYear} ~ ${maxYear - 1}`,
              })
              .required(t(FIELD_REQUIRED_MESSAGE))
          : yup.string().required(t(FIELD_REQUIRED_MESSAGE)),
        file: yup.mixed().required(t(FIELD_REQUIRED_MESSAGE)),
      })
      .required();
  }, [isMandatoryYearInputField, t]);

  const { control, formState, handleSubmit, setValue } =
    useForm<ImportFormData>({
      ...(initialValues && {
        defaultValues: getInitialValues(initialValues),
      }),
      resolver: yupResolver(schema as any),
    });

  const { mutateAsync: createUpload, isLoading } = useMutation(
    async (data: ImportFormData) => {
      const promise = new Promise((resolve, reject) => {
        setTimeout(() => {
          onRequestClose();
          toast.success(t('pages.uploads.toast.success.create'));

          return reject();
        }, 5000);
      });

      return Promise.race([
        createImportRequest({
          ...data,
          fileName: data.file.name,
        }),
        promise,
      ]);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('imports');
        toast.success(t('pages.uploads.toast.success.create'));
        onRequestClose();
      },
    }
  );

  const onSubmit = useCallback(
    async (data: ImportFormData) => {
      await createUpload(data);
    },
    [createUpload]
  );

  return (
    <Modal
      {...rest}
      open={isOpen}
      size="small"
      onClose={onRequestClose}
      closeIcon
      closeOnDimmerClick={false}
    >
      <Modal.Header>{t('pages.uploads.form.title')}</Modal.Header>
      <FormContent
        control={control}
        formRef={formRef}
        setValue={setValue}
        formState={formState}
        handleSubmit={handleSubmit}
        onSubmit={onSubmit}
        setIsMandatoryYearInputField={setIsMandatoryYearInputField}
      />
      <Modal.Actions>
        <ButtonGDY
          type="submit"
          primary
          loading={isLoading}
          disabled={isLoading}
          onClick={() => {
            formRef.current?.handleSubmit();
          }}
        >
          {t('components.buttons.saveButton')}
        </ButtonGDY>
      </Modal.Actions>
    </Modal>
  );
}

export { UploadFormModal };
