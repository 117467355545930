/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';

import { getEmployeeByUserId } from '../services/employees';
import { Mandatory } from '../types/mandatory';
import { useAuth } from './useAuth';

interface IProps {
  mandatoryId: number;
  sbuId: number;
  userId?: string;
}

type EmployeeByUserIdResponseProps = {
  associatedData?: Partial<AssociatedDataProps>;
  isAssociatedDataLoading: boolean;
};

export type AssociatedDataProps = {
  name?: string;
  businessLine?: string;
  jobTitle?: string;
  facilityDescription?: string;
  mandatory?: Mandatory;
};

export function useEmployeeByUserId({
  mandatoryId,
  sbuId,
  userId,
}: IProps): EmployeeByUserIdResponseProps {
  const { user: loggedUser } = useAuth();
  const [associatedData, setAssociatedData] = useState<AssociatedDataProps>({});
  const [isAssociatedDataLoading, setIsAssociatedDataLoading] = useState(false);

  useEffect(() => {
    const getAssociateData = async () => {
      try {
        if (userId && sbuId && mandatoryId) {
          setIsAssociatedDataLoading(true);
          const employee = await getEmployeeByUserId({
            mandatoryId: Number(mandatoryId),
            sbuId: Number(sbuId),
            userId,
          });
          setAssociatedData({
            ...employee,
            facilityDescription: employee.sbu.country,
          });
        } else {
          throw new Error();
        }
      } catch {
        if (loggedUser) {
          setAssociatedData({
            name: `${loggedUser?.firstName} ${loggedUser?.lastName}`,
            businessLine: loggedUser?.costCenterDescription,
            facilityDescription: loggedUser?.facilityDescription,
            jobTitle: loggedUser?.title,
          });
        }
      } finally {
        setIsAssociatedDataLoading(false);
      }
    };
    getAssociateData();
  }, [loggedUser, mandatoryId, sbuId, userId]);

  return {
    associatedData,
    isAssociatedDataLoading,
  };
}
