/* eslint-disable @typescript-eslint/no-shadow */
import { useCallback, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import { Icon as IconSemantic } from 'semantic-ui-react';

import projectPackage from '../../../../package.json';
import { useAuth } from '../../../hooks/useAuth';
import useOnClickOutside from '../../../hooks/useOnClickOutside';
import { useSubordinatesByLoggedUser } from '../../../hooks/useSubordinatesByLoggedUser';
import { Role } from '../../../types/roles';
import { menuItems } from './mocks/menu';
import {
  Container,
  Navigation,
  Item,
  Info,
  Logo,
  ToggleButton,
  Items,
  SubItem,
  Category,
} from './styles';

interface Props {
  show: boolean;
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
  handleShow: () => void;
}

function Sidebar({ show, setShow, handleShow }: Props) {
  const { t } = useTranslation();
  const { user } = useAuth();
  const ref = useRef(null);
  const location = useLocation();
  const [categoryIndexOpen, setCategoryIndexOpen] = useState<number>();
  const currentYear = new Date().getFullYear();

  useOnClickOutside(ref, () => setShow(false));

  const { hasSubordinates } = useSubordinatesByLoggedUser();

  const handleClose = () => {
    if (show) {
      setShow(false);
      setCategoryIndexOpen(0);
    }
  };

  const getMenuItemPermission = useCallback(
    (roleId: number) => {
      if (roleId === Role.Reports && hasSubordinates) {
        return true;
      }
      return user?.roles?.map(({ id }) => id).includes(roleId);
    },
    [hasSubordinates, user?.roles]
  );

  const handleCategoryClick = useCallback(
    (index: number) => {
      const isCategoryOpen = categoryIndexOpen === index;

      if (!show) {
        handleShow();
        return setCategoryIndexOpen(index);
      }

      if (isCategoryOpen) {
        return setCategoryIndexOpen(0);
      }

      return setCategoryIndexOpen(index);
    },
    [categoryIndexOpen, show, handleShow]
  );

  return (
    <Container show={show} ref={ref} id="layout__sidebar">
      <Navigation>
        <ToggleButton
          show={show}
          name={show ? 'close' : 'bars'}
          onClick={handleShow}
        />

        <hr style={{ opacity: 0.1 }} />

        <Items show={show}>
          {menuItems.map((item, index) => {
            if (item.role && !getMenuItemPermission(item.role)) {
              return undefined;
            }

            if (item.subItems) {
              return (
                <Category
                  key={item.name}
                  active={item.subItems
                    .map(({ path }) => path)
                    .includes(location.pathname)}
                  isSidebarOpen={show}
                  isCategoryOpen={categoryIndexOpen === index}
                  onClick={() => handleCategoryClick(index)}
                >
                  <span className="menu__category--item">
                    <i>
                      <item.Icon />
                    </i>
                    <span>
                      {t(item.name as any)}
                      <IconSemantic
                        name={
                          categoryIndexOpen === index
                            ? 'angle down'
                            : 'angle right'
                        }
                      />
                    </span>
                  </span>

                  <span className="menu__category--subItems">
                    {item.subItems.map((subItem) => {
                      if (
                        subItem.role &&
                        !getMenuItemPermission(subItem.role)
                      ) {
                        return undefined;
                      }

                      return (
                        <SubItem
                          key={subItem.name}
                          active={location.pathname === subItem.path}
                        >
                          <Link to={subItem.path ?? '*'} onClick={handleClose}>
                            <span>{t(subItem.name as any)}</span>
                          </Link>
                        </SubItem>
                      );
                    })}
                  </span>
                </Category>
              );
            }

            return (
              <Item key={item.name} active={location.pathname === item.path}>
                <Link to={item.path ?? '*'} onClick={handleClose}>
                  <i>
                    <item.Icon size={18} />
                  </i>
                  <span>{t(item.name as any)}</span>
                </Link>
              </Item>
            );
          })}
        </Items>
      </Navigation>

      <Info show={show}>
        <Logo />

        <div className="info__version-and-year">
          <span className="info__version">v{projectPackage.version}</span>
          <span className="info__year">© {currentYear}</span>
        </div>

        <div className="info__sentence">The Goodyear Tire & Rubber Company</div>
      </Info>
    </Container>
  );
}

export { Sidebar };
