import { AxiosError } from 'axios';
import { useRef, useCallback, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';
import { Modal, ModalProps } from 'semantic-ui-react';

import { ButtonGDY } from '../../../../components/Form/ButtonGDY';
import {
  createNewMessage,
  getMessageById,
  updateMessage,
} from '../../../../services/messages';
import { queryClient } from '../../../../services/queryClient';
import { MessageDataForm } from '../../../../types/messages';
import { FormContent } from '../FormContent';

type MessageFormModalProps = ModalProps & {
  isOpen: boolean;
  messageId?: number;
  onRequestClose: () => void;
  closeModalOnSave: () => void;
};

function MessageFormModal({
  isOpen,
  messageId,
  onRequestClose,
  closeModalOnSave,
  ...rest
}: MessageFormModalProps) {
  const { t } = useTranslation();
  const formRef = useRef<HTMLFormElement>(null);
  const { control, formState, handleSubmit, watch, reset, setValue } =
    useForm<MessageDataForm>();

  useEffect(() => {
    if (messageId) {
      getMessageById(Number(messageId)).then((res) => {
        reset({
          ...res,
          sbuId: res.sbu.id,
          region: res.sbu.region,
          typeId: res.type.id,
        });
      });
    }
  }, [messageId, reset]);

  const createMessage = useMutation(
    async (data: MessageDataForm) => {
      return createNewMessage(data);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('MESSAGES_FILTERED_LIST');
        closeModalOnSave();
        toast.success(t('components.toast.success.create'));
      },
      onError: (error: AxiosError<any>) => {
        toast.error(error.response?.data?.message);
      },
    }
  );

  const editMessage = useMutation(
    async (data: MessageDataForm) => {
      return updateMessage(data);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('MESSAGES_FILTERED_LIST');
        closeModalOnSave();
        toast.success(t('components.toast.success.edit'));
      },
      onError: () => {
        toast.error(t('components.toast.error.edit'));
      },
    }
  );

  const onSubmit = useCallback(
    async (data: MessageDataForm) => {
      if (data.id) {
        await editMessage.mutateAsync(data);
      } else {
        await createMessage.mutateAsync(data);
      }
    },
    [editMessage, createMessage]
  );

  return (
    <Modal
      open={isOpen}
      closeIcon
      size="small"
      onClose={onRequestClose}
      closeOnDimmerClick={false}
      {...rest}
    >
      <Modal.Header>{t('pages.messages.form.title')}</Modal.Header>
      <FormContent
        control={control}
        formRef={formRef}
        onSubmit={onSubmit}
        watch={watch}
        setValue={setValue}
        formState={formState}
        handleSubmit={handleSubmit}
      />
      <Modal.Actions>
        <ButtonGDY
          primary
          type="submit"
          onClick={() => {
            formRef.current?.handleSubmit();
          }}
          loading={createMessage.isLoading || editMessage.isLoading}
          disabled={createMessage.isLoading || editMessage.isLoading}
        >
          {t('components.buttons.saveButton')}
        </ButtonGDY>
      </Modal.Actions>
    </Modal>
  );
}

export { MessageFormModal };
