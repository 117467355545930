import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';

import { queryClient } from '../../../services/queryClient';
import { deleteTraining } from '../../../services/training';

interface IProps {
  handleConfirmDeleteModalClose(): void;
}

export function useHandleDeleteTraining({
  handleConfirmDeleteModalClose,
}: IProps) {
  const { t } = useTranslation();
  const handleDeleteTraining = useMutation(
    async (id: number) => {
      const response = await deleteTraining(id);
      handleConfirmDeleteModalClose();
      return response;
    },
    {
      onSuccess: () => {
        toast.success(t('components.toast.success.delete'));
        queryClient.invalidateQueries('trainings');
      },
      onError: () => {
        toast.error(t('components.toast.error.delete'));
      },
    }
  );

  return {
    handleDeleteTraining,
  };
}
