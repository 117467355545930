import { useQuery } from 'react-query';

import { getListSubordinates } from '../services/employees';
import { useMandatoryByLoggedUser } from './useMandatoryByLoggedUser';

export function useSubordinatesByLoggedUser() {
  const { mandatories: mandatoriesByLoggedUser } = useMandatoryByLoggedUser();

  const { data: subordinates } = useQuery(
    ['SUBORDINATES', mandatoriesByLoggedUser, { page: 1 }],
    async () =>
      getListSubordinates({
        mandatoryId: mandatoriesByLoggedUser?.at(-1)?.id,
        sbuId: mandatoriesByLoggedUser?.at(-1)?.sbu.id,
        pageNumber: 1,
        pageSize: 2,
      }),
    { enabled: !!mandatoriesByLoggedUser, retry: false }
  );

  return { subordinates, hasSubordinates: !!subordinates?.content.length };
}
