import { useTranslation } from 'react-i18next';
import { Table as TableSemantic, Pagination, Icon } from 'semantic-ui-react';

import { Profile, ProfilesResponseDataProps } from '../../../../types/profiles';
import { TableBody } from '../TableBody';

interface ITableProps {
  handleFormModalOpen: (profile: Profile) => void;
  handleDeleteModalOpen: (profileId: number) => void;
  profiles?: ProfilesResponseDataProps;
  page: number;
  handlePageChange: any;
}

function Table({
  handleFormModalOpen,
  handleDeleteModalOpen,
  profiles,
  page,
  handlePageChange,
}: ITableProps) {
  const { t } = useTranslation();
  return (
    <>
      <div className="table__scrollable">
        <TableSemantic className="table" unstackable>
          <TableSemantic.Header className="thead">
            <TableSemantic.Row className="tr">
              <TableSemantic.HeaderCell
                width={1}
                className="th idCol profile"
                textAlign="center"
                scope="col"
              >
                {t('pages.profiles.id')}
              </TableSemantic.HeaderCell>

              <TableSemantic.HeaderCell
                className="th group profile sbuCol"
                scope="col"
              >
                {t('pages.profiles.accessGroup')}
              </TableSemantic.HeaderCell>

              <TableSemantic.HeaderCell
                className="th sbu profile"
                textAlign="center"
                scope="col"
              >
                {t('pages.profiles.sbu')}
              </TableSemantic.HeaderCell>

              <TableSemantic.HeaderCell
                className="th sbu countryLocation"
                textAlign="center"
                scope="col"
              >
                {t('pages.profiles.countryLocation')}
              </TableSemantic.HeaderCell>

              <TableSemantic.HeaderCell
                className="th roles profile"
                scope="col"
              >
                {t('pages.profiles.roles')}
              </TableSemantic.HeaderCell>

              <TableSemantic.HeaderCell
                className="th type profile"
                textAlign="center"
                scope="col"
              >
                {t('pages.profiles.type')}
              </TableSemantic.HeaderCell>

              <TableSemantic.HeaderCell className="th desc profile" scope="col">
                {t('pages.profiles.description')}
              </TableSemantic.HeaderCell>

              <TableSemantic.HeaderCell className="th action">
                <Icon name="cog" size="large" />
              </TableSemantic.HeaderCell>
            </TableSemantic.Row>
          </TableSemantic.Header>
          <TableBody
            profiles={profiles?.content}
            handleFormModalOpen={handleFormModalOpen}
            handleDeleteModalOpen={handleDeleteModalOpen}
          />
        </TableSemantic>
      </div>
      <div className="table__pagination">
        <Pagination
          page={page}
          totalPages={profiles?.totalPages ?? 0}
          handlePageChange={handlePageChange}
        />
      </div>
    </>
  );
}

export { Table };
