import styled from 'styled-components';

export const Container = styled.div`
  position: relative;

  display: flex;
  flex: 1;

  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
`;

export const Content = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  height: 100vh;

  > p {
    margin-top: 15px;

    color: ${({ theme }) => theme.color.white};

    > a {
      color: ${({ theme }) => theme.color.secondary};
    }
  }

  > img {
    width: 350px;
    filter: drop-shadow(2px 2px 6px #00000080);
  }

  @media (max-width: 800px) {
    > img {
      width: 350px;
    }
  }
`;

export const AuthenticationContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  margin-top: 38px;
  padding: ${({ theme }) => `${theme.spacing[6]}px ${theme.spacing[12]}px`};

  border-radius: 5px;
  background-color: ${({ theme }) => theme.color.white};

  position: relative;

  > h1 {
    text-align: center;
    font-size: 28px;
    font-weight: 700;
    color: ${({ theme }) => theme.color.primary};
  }

  > p {
    color: ${({ theme }) => theme.color.text_light};
  }

  form {
    width: 100%;

    button {
      width: 100%;
    }
  }

  @media (max-width: 800px) {
    padding: ${({ theme }) => `${theme.spacing[3]}px ${theme.spacing[3]}px`};

    & {
      margin: 38px 20px 0 20px;
    }
  }
`;

export const LoginButtons = styled.div`
  margin-bottom: 1em;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  gap: ${({ theme }) => `${theme.spacing[3]}px`};

  > button {
    font-size: 1.2rem !important;
    height: 45px;
    width: 350px;
    color: ${({ theme }) => theme.color.white};
    white-space: nowrap;
  }
`;

export const BackgroundContainer = styled.div`
  background-color: #000;
  height: 100%;
  width: 100%;

  position: fixed;
  z-index: -1;

  @media (max-width: 1600px) {
    & {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .fade-enter {
    filter: brightness(0);
  }

  .fade-enter-active {
    filter: brightness(40%);
    transition: all 2s ease-in-out;
  }

  .fade-exit {
    filter: brightness(40%);
  }

  .fade-exit-active {
    filter: brightness(0);
    transition: all 2s ease-in-out;
  }
`;

export const BackgroundImage = styled.img`
  background-color: #000;
  width: 100%;
  position: fixed;
  z-index: -1;

  filter: brightness(40%);

  @media (max-width: 1600px) {
    & {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
`;

export const Languages = styled.div`
  margin-top: ${({ theme }) => `${theme.spacing[3]}px`};
`;
