import ReactGA from 'react-ga4';
import { I18nextProvider } from 'react-i18next';
import { QueryClientProvider } from 'react-query';
import { BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { ThemeProvider } from 'styled-components';

import { AuthProvider } from './contexts/Auth';
import Routes from './routes';
import { queryClient } from './services/queryClient';
import { GlobalStyle } from './styles/global';
import 'react-toastify/dist/ReactToastify.css';
import { REACT_APP_TAG_GOOGLE } from './settings/constants';
import theme from './styles/theme';
import i18n from './locales';

ReactGA.initialize(REACT_APP_TAG_GOOGLE);

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <ThemeProvider theme={theme}>
          <AuthProvider>
            <I18nextProvider i18n={i18n}>
              <Routes />
              <GlobalStyle />
            </I18nextProvider>
            <ToastContainer />
          </AuthProvider>
        </ThemeProvider>
      </BrowserRouter>
    </QueryClientProvider>
  );
}

export default App;
