/* eslint-disable react-hooks/exhaustive-deps */
import html2canvas from 'html2canvas';
import JsPDF from 'jspdf';
import { useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { Dimmer, Loader } from 'semantic-ui-react';

import { WelcomeBar } from '../../../../../components/WelcomeBar';
import { MandatoryDetailsResponseProps } from '../../../../../types/mandatoryDetails';
import { MessagesResponseProps } from '../../../../../types/messages';
import { PageOne } from '../PageOne';
import { PageTwo } from '../PageTwo';
import { Container, Content, Page } from './styles';

interface WelcomeProps {
  message?: string;

  name?: string;
  businessLine?: string;
  jobTitle?: string;
  facilityDescription?: string;
  mandatory?: any;
}

interface PageOneProps {
  mandatoryDetails?: MandatoryDetailsResponseProps;
  noteMessage?: string;
}

interface PageTwoProps {
  trainings?: MandatoryDetailsResponseProps;
  messages?: MessagesResponseProps;
  qrCode?: string;
}

type PagesReturnProps = WelcomeProps & PageOneProps & PageTwoProps;

export function DevelopmentPDF() {
  const navigate = useNavigate();

  const {
    message,
    name,
    businessLine,
    jobTitle,
    facilityDescription,
    mandatory,
    mandatoryDetails,
    messages,
    qrCode,
    noteMessage,
  } = useMemo((): PagesReturnProps => {
    const welcome = window.sessionStorage.getItem('@welcome');
    const page1 = window.sessionStorage.getItem('@page1');
    const page2 = window.sessionStorage.getItem('@page2');

    const welcomeObj: WelcomeProps = JSON.parse(welcome as string);
    const page1Obj: PageOneProps = JSON.parse(page1 as string);
    const page2Obj: PageTwoProps = JSON.parse(page2 as string);

    return {
      ...welcomeObj,
      ...page1Obj,
      ...page2Obj,
    };
  }, []);

  function exportPDF() {
    const inputPage1 = document.getElementById('page1');
    const inputPage2 = document.getElementById('page2');
    const pdf = new JsPDF('landscape', 'mm', 'a4');

    if (inputPage1 && inputPage2) {
      html2canvas(inputPage1, {
        logging: true,
        useCORS: true,
        scale: 2,
      }).then((canvas1) => {
        html2canvas(inputPage2, {
          logging: true,
          useCORS: true,
          scale: 2,
        }).then((canvas2) => {
          const imgWidth = 297;
          const imgHeight = 210;

          const imgData1 = canvas1.toDataURL('img/pdf');
          const imgData2 = canvas2.toDataURL('img/pdf');

          pdf.addImage(imgData1, 'PNG', 0, 0, imgWidth, imgHeight);
          pdf.addPage();
          pdf.addImage(imgData2, 'PNG', 0, 0, imgWidth, imgHeight);

          pdf.save(`${name?.replace(' ', '_')}_DEVELOPMENT.pdf`);

          navigate(-1);
        });
      });
    }
  }

  useEffect(() => {
    exportPDF();
  }, []);

  return (
    <>
      <Dimmer active>
        <Loader active />
      </Dimmer>
      <Container id="developmentPrint">
        <Page className="page" id="page1">
          <WelcomeBar
            message={message}
            employee={{
              name,
              businessLine,
              jobTitle,
              facilityDescription,
              mandatory,
            }}
          />
          <Content>
            <PageOne
              mandatoryDetails={mandatoryDetails}
              noteMessage={noteMessage}
            />
          </Content>
        </Page>

        <Page className="page" id="page2">
          <Content>
            <PageTwo
              trainings={mandatoryDetails}
              messages={messages}
              qrCode={qrCode}
            />
          </Content>
        </Page>
      </Container>
    </>
  );
}
