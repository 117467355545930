/* eslint-disable react-hooks/exhaustive-deps */
import {
  createContext,
  useState,
  ReactNode,
  useMemo,
  useEffect,
  SetStateAction,
  useCallback,
} from 'react';

import api from '../services/api';
import { userInfoRequest, userPermissionsRequest } from '../services/user';
import { User } from '../types/user';
import { UserPermissions } from '../types/userPermissions';
import cookies from '../utils/cookies';

type UserProps = User & UserPermissions;

export type AuthContextData = {
  signed: boolean;
  user?: UserProps;
  token?: string;
  handleGetToken: () => string | null;
  handleFindUser: () => Promise<void>;
  signOut: () => void;
  isSplashLoading: boolean;
  setIsSplashLoading: React.Dispatch<SetStateAction<boolean>>;
};

type AuthProviderProps = {
  children: ReactNode;
};

export const AuthContext = createContext<AuthContextData>(
  {} as AuthContextData
);

export function AuthProvider({ children }: AuthProviderProps) {
  const [user, setUser] = useState<UserProps>();
  const [token, setToken] = useState<string>();
  const [isSplashLoading, setIsSplashLoading] = useState(false);

  const handleFindUser = useCallback(async () => {
    try {
      const [userInfo, userPermission] = await Promise.all([
        userInfoRequest(),
        userPermissionsRequest(),
      ]);

      const userData = {
        firstName: userInfo?.firstName,
        lastName: userInfo?.lastName,
        userId: userInfo?.userId,
        smtpEmailAddress: userInfo?.smtpEmailAddress,
        costCenterDescription: userInfo?.costCenterDescription,
        title: userInfo?.title,
        facilityDescription: userInfo?.facilityDescription,
        photoBase64: userInfo?.photoBase64,

        roles: userPermission?.[0]?.roles,
        sbu: userPermission?.[0]?.sbu,
        type: userPermission?.[0]?.type,
      };

      setUser(userData);
    } catch (error) {
      if (error instanceof Error) {
        throw new Error(error.message);
      }
    }
  }, []);

  const handleGetToken = (): string | null => {
    const params = new URLSearchParams(window.location.search);
    const jwt = params.get('jwt');

    if (!jwt) {
      return null;
    }

    api.defaults.headers.common.jwt = jwt;

    cookies.setCookie('@TOKEN', jwt, 10);
    setToken(jwt);

    return jwt;
  };

  const handleLoadData = () => {
    const tokenCookie = cookies.getCookie('@TOKEN');

    if (!tokenCookie) {
      if (window.location.href !== `${window.location.origin}/auth`) {
        window.location.href = `${window.location.origin}/auth`;
        return;
      }
      return;
    }

    setToken(tokenCookie);
    api.defaults.headers.common.jwt = tokenCookie;
    handleFindUser();
  };

  const signOut = useCallback(() => {
    delete api.defaults.headers.common.jwt;
    cookies.removeCookie('@TOKEN');
    setToken(undefined);
    setUser(undefined);
  }, []);

  useEffect(() => {
    handleLoadData();
  }, [token]);

  const authContextData: AuthContextData = useMemo(() => {
    return {
      signed: !!token,
      user,
      token,
      handleGetToken,
      handleFindUser,
      signOut,
      isSplashLoading,
      setIsSplashLoading,
    };
  }, [
    user,
    token,
    handleGetToken,
    handleFindUser,
    signOut,
    isSplashLoading,
    setIsSplashLoading,
  ]);

  return (
    <AuthContext.Provider value={authContextData}>
      {children}
    </AuthContext.Provider>
  );
}
