/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';
import { Dropdown, Icon } from 'semantic-ui-react';

import GoodyearLogoImg from '../../assets/goodyear-logo.png';
import BackgroundImageMock from '../../assets/login-background.jpg';
import { Alert } from '../../components/Alert';
import { Button } from '../../components/Form/Button';
import { useAuth } from '../../hooks/useAuth';
import { getBackgroundImages } from '../../services/backgroundImages';
import { ENV } from '../../types/env';
import {
  Container,
  Content,
  AuthenticationContent,
  LoginButtons,
  BackgroundContainer,
  BackgroundImage,
} from './styles';

export function Login() {
  const { handleGetToken, handleFindUser } = useAuth();
  const { i18n, t } = useTranslation();
  const navigate = useNavigate();
  const [showBackground, setShowBackground] = useState(false);
  const [backgroundCount, setBackgroundCount] = useState(0);
  const INTERVAL = 1000 * 10;

  const {
    data: backgroundData,
    isLoading: isBackgroundLoading,
    isError: isBackgroundError,
  } = useQuery(['BG_IMAGE'], async () => getBackgroundImages(), {
    refetchOnWindowFocus: false,
    refetchInterval: INTERVAL,
  });

  const handleShowBackgroundImage = () => {
    if (!isBackgroundLoading) {
      setShowBackground(true);

      setTimeout(() => {
        setShowBackground(false);
        setBackgroundCount((prev) => prev + 1);
      }, INTERVAL - 2000);
    }
  };

  useEffect(() => {
    handleShowBackgroundImage();
  }, [backgroundData, isBackgroundLoading]);

  const captureTokenUrl = () => {
    try {
      const token = handleGetToken();

      if (token) {
        handleFindUser();
        navigate('/');
      }
    } catch (error) {
      if (error instanceof Error) {
        Alert({ message: error.message, type: 'error' });
      }
    }
  };

  const handleNavigateLogin = () => {
    window.location.href = ENV.LOGIN_URL;
  };

  useEffect(() => {
    captureTokenUrl();
  }, []);

  const handleChangeLanguage = (value: string) => {
    i18n.changeLanguage(value);
    localStorage.setItem('@LANGUAGE', i18n.language);
  };

  return (
    <Container>
      <Content>
        <img src={GoodyearLogoImg} alt="Goodyear" draggable={false} />

        <AuthenticationContent>
          <h1>{t('application.name')?.toUpperCase()}</h1>

          <p>{t('pages.login.content.credentialsAccess')}</p>

          <LoginButtons>
            <Button
              title={t('pages.login.button.loginGoodyear')}
              iconName="key"
              onClick={handleNavigateLogin}
            />
          </LoginButtons>

          <Dropdown
            trigger={
              <>
                <Icon name="globe" /> {t(`languages.${i18n.language}`)}
              </>
            }
            inline
            options={[
              {
                key: 'pt-BR',
                flag: 'brazil',
                text: 'Português',
                value: 'pt-BR',
                active: i18n.language === 'pt-BR',
              },
              {
                key: 'en-US',
                flag: 'united states',
                text: 'English',
                value: 'en-US',
                active: i18n.language === 'en-US',
              },
            ]}
            onChange={(_, data) => handleChangeLanguage(data.value as string)}
          />
        </AuthenticationContent>
      </Content>

      <BackgroundContainer>
        <CSSTransition
          in={showBackground}
          timeout={backgroundCount > 0 ? 2000 : 0}
          classNames="fade"
          unmountOnExit
        >
          {isBackgroundError ? (
            <BackgroundImage
              src={BackgroundImageMock}
              alt="Background"
              draggable={false}
            />
          ) : (
            <BackgroundImage
              src={`data:image/png;base64,${backgroundData?.byteArray}`}
              alt="Background"
              draggable={false}
            />
          )}
        </CSSTransition>
      </BackgroundContainer>
    </Container>
  );
}

export default Login;
