/* eslint-disable react/no-danger */
import { useTranslation } from 'react-i18next';
import { Divider, Loader } from 'semantic-ui-react';
import { useTheme } from 'styled-components';

import { AssociatedDataProps } from '../../hooks/useEmployeeByUserId';
import { DateHelper } from '../../utils/date';
import { InformationCard } from '../Cards/InformationCard';
import { LoaderCentered } from '../LoaderCentered';
import { LoaderLine } from '../LoarderLine';
import { Container } from './styles';

interface IProps {
  message?: string;
  isLoadingMessage?: boolean;

  isEmployeeLoading?: boolean;
  employee?: AssociatedDataProps;
}

export function WelcomeBar({
  message,
  isLoadingMessage,
  isEmployeeLoading,
  employee,
}: IProps) {
  const { t } = useTranslation();
  const theme = useTheme();

  function renderWelcomeMessage() {
    if (isLoadingMessage) {
      return <LoaderCentered />;
    }

    if (message) {
      return (
        <>
          <span dangerouslySetInnerHTML={{ __html: message }} />
          <Divider />
        </>
      );
    }

    return undefined;
  }

  function renderEmployeeName() {
    if (isEmployeeLoading) {
      return <Loader active inline size="small" />;
    }

    if (employee?.name) {
      return `${t('pages.home.welcome.title')}, ${employee?.name
        ?.split(' ')[0]
        .toLowerCase()}!`;
    }

    return undefined;
  }

  return (
    <Container>
      {isLoadingMessage || isEmployeeLoading ? (
        <LoaderLine numberOfLines={8} />
      ) : (
        <>
          <div className="welcome__title">
            <span className="welcome__title--userName">
              {renderEmployeeName()}
            </span>
          </div>

          <div className="welcome__description">{renderWelcomeMessage()}</div>
        </>
      )}

      <InformationCard
        title={t('pages.home.employeeData.title', {
          date: DateHelper.dateFormat(employee?.mandatory?.year),
        })}
      >
        {isEmployeeLoading ? (
          <LoaderLine numberOfLines={4} />
        ) : (
          <>
            <div>{employee?.name}</div>
            <div>{employee?.businessLine}</div>
            <div>{employee?.jobTitle}</div>
            <div>{employee?.facilityDescription}</div>
          </>
        )}
      </InformationCard>

      <InformationCard
        iconName="warning sign"
        title={t('pages.home.noteWelcome.title')}
        bgColor={theme.palette.GENERAL.PRIMARY}
      >
        <span
          dangerouslySetInnerHTML={{
            __html: t('pages.home.noteWelcome.text'),
          }}
        />
      </InformationCard>
    </Container>
  );
}
