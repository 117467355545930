const downloadPdfFromBase64 = (base64Data: string, fileName = 'file.pdf') => {
  try {
    const byteCharacters = atob(base64Data);
    const byteNumbers = Array.from(byteCharacters, (char) =>
      char.charCodeAt(0)
    );
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: 'application/pdf' });

    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = fileName;

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    window.URL.revokeObjectURL(link.href);
  } catch (error) {
    console.error('Erro ao processar o download:', error);
  }
};

export const FileHelper = {
  downloadPdfFromBase64,
};
