/* eslint-disable react/no-array-index-key */
import { Placeholder } from 'semantic-ui-react';

type LoaderLineProps = {
  numberOfLines: number;
};

export function LoaderLine({ numberOfLines = 1 }: LoaderLineProps) {
  return (
    <Placeholder fluid>
      {Array(numberOfLines)
        ?.fill('')
        ?.map((_, index) => {
          return <Placeholder.Line key={index} />;
        })}
    </Placeholder>
  );
}
