import { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { Icon, Button, Confirm, Loader, Checkbox } from 'semantic-ui-react';
import { useTheme } from 'styled-components';

import { Filter } from '../../components/Filter';
import { ButtonGDY } from '../../components/Form/ButtonGDY';
import { FilterFormGroup } from '../../components/Form/FilterFormGroup';
import { useDownloadUserGuide } from '../../hooks/useDownloadUserGuide';
import { importsRequest } from '../../services/imports';
import { EStatusId, ImportFormData, ImportParams } from '../../types/import';
import { ContentFilter } from './components/ContentFilter';
import { Table } from './components/Table';
import { UploadFormModal } from './components/UploadFormModal';
import { useHandleCreateNewYear } from './hooks/useHandleCreateNewYear';
import { useHandleDownloadTemplate } from './hooks/useHandleDownloadTemplate';
import {
  Container,
  Toolbar,
  ToolbarContent,
  ToolbarColumn,
  ConfirmModalContent,
} from './styles';

function Uploads() {
  const { t } = useTranslation();
  const theme = useTheme();
  const [page, setPage] = useState(1);
  const [openUploadFormModal, setOpenUploadFormModal] =
    useState<boolean>(false);
  const [openConfirmNewYear, setOpenConfirmNewYear] = useState<boolean>(false);
  const [openFilter, setOpenFilter] = useState<boolean>(false);
  const [query, setQuery] = useState<ImportParams>({
    sbuId: undefined,
    mandatoryId: undefined,
  });
  const { control, setValue, reset, handleSubmit, formState } =
    useForm<ImportParams>({
      defaultValues: undefined,
      mode: 'onChange',
    });
  const [isCopyPreviousYearMessages, setIsCopyPreviousYearMessages] =
    useState(false);
  const [refreshList, setRefreshList] = useState(false);

  const { onDownloadUserQuide, isLoading: isDownloadUserGuideLoading } =
    useDownloadUserGuide();

  function handleUploadFormModal() {
    setOpenUploadFormModal(true);
  }

  function handleUploadFormModalClose() {
    setOpenUploadFormModal(false);
  }

  const { data: imports, isLoading: isLoadingImports } = useQuery(
    ['imports', { page, ...query }],
    async () => {
      return importsRequest({
        pageNumber: page - 1,
        pageSize: 10,
        ...query,
      });
    },
    {
      enabled: !!query.sbuId && !!query.mandatoryId,
      refetchInterval: refreshList ? 2000 : false,
    }
  );

  useEffect(() => {
    if (
      imports?._embedded?.importDTOList?.[0]?.status.id === EStatusId.Saving
    ) {
      setRefreshList(true);
    } else {
      setRefreshList(false);
    }
  }, [imports?._embedded?.importDTOList]);

  function handleClearFilters() {
    reset({
      region: null,
      sbuId: null,
      mandatoryId: null,
    });
    setQuery({});
  }

  const onSubmit = useCallback((data: ImportParams) => {
    setQuery({ ...data });
    setOpenFilter(false);
  }, []);

  const { handleDownloadTemplate, isLoadingDownloadTemplate } =
    useHandleDownloadTemplate();

  const { handleCreateNewYear, isLoadingCreateNewYear } =
    useHandleCreateNewYear({
      control,
      query,
      setQuery,
      setValue,
      isCopyPreviousYearMessages,
      setOpenConfirmNewYear,
    });

  return (
    <>
      <Container>
        <div className="page__title">{t('menu.uploads')}</div>
        <Toolbar>
          <ToolbarContent>
            <ToolbarColumn>
              <Button
                className="add-button"
                icon
                labelPosition="left"
                onClick={handleUploadFormModal}
                style={{
                  backgroundColor: theme.palette.GENERAL.PRIMARY,
                  color: theme.palette.GENERAL.SECONDARY,
                }}
              >
                <Icon name="add" />
                {t('components.buttons.newButton')}
              </Button>
            </ToolbarColumn>

            <ToolbarColumn>
              <ButtonGDY
                icon
                labelPosition="left"
                disabled={
                  (!query.mandatoryId && !query.sbuId) ||
                  !imports?._embedded?.importDTOList?.[0].mandatory.released
                }
                loading={isLoadingCreateNewYear}
                onClick={() => setOpenConfirmNewYear((oldValue) => !oldValue)}
                style={{
                  backgroundColor: theme.palette.GENERAL.PRIMARY,
                  color: theme.palette.GENERAL.SECONDARY,
                }}
              >
                <Icon name="plus" />
                {imports?._embedded?.importDTOList?.[0].mandatory.released}
                {t('pages.uploads.createNewYear')}
              </ButtonGDY>

              <ButtonGDY
                icon
                labelPosition="left"
                loading={isDownloadUserGuideLoading}
                onClick={onDownloadUserQuide}
              >
                <Icon name="file alternate" />
                {t('pages.uploads.downloadManual')}
              </ButtonGDY>

              <ButtonGDY
                icon
                labelPosition="left"
                loading={isLoadingDownloadTemplate}
                onClick={() => handleDownloadTemplate()}
              >
                <Icon name="file excel" />
                {t('pages.uploads.downloadTemplate')}
              </ButtonGDY>

              <ButtonGDY
                className="filter-list"
                icon="filter"
                active={openFilter}
                onClick={() => setOpenFilter((oldValue) => !oldValue)}
              />
            </ToolbarColumn>
          </ToolbarContent>
        </Toolbar>
        {isLoadingImports ? (
          <Loader active />
        ) : (
          <Table data={imports} onChangePage={setPage} />
        )}
      </Container>
      {openUploadFormModal && (
        <UploadFormModal
          initialValues={{
            ...({} as ImportFormData),
            mandatoryYear: undefined,
          }}
          isOpen={openUploadFormModal}
          onRequestClose={handleUploadFormModalClose}
        />
      )}

      <Confirm
        header={t('pages.uploads.confirm.title')}
        content={
          <ConfirmModalContent>
            <p>{t('pages.uploads.confirm.confirmNewYear')}</p>
            <Checkbox
              label={t('pages.uploads.confirm.copyPrevYearMessages')}
              checked={isCopyPreviousYearMessages}
              onChange={() =>
                setIsCopyPreviousYearMessages((oldState) => !oldState)
              }
            />
          </ConfirmModalContent>
        }
        cancelButton={{
          content: t('components.buttons.cancelButton'),
        }}
        confirmButton={{
          content: t('components.buttons.confirmButton'),
          negative: true,
        }}
        open={openConfirmNewYear}
        onCancel={() => setOpenConfirmNewYear((oldValue) => !oldValue)}
        onConfirm={() => handleCreateNewYear()}
      />

      <Filter
        onApplyClick={handleSubmit(onSubmit)}
        onClearClick={handleClearFilters}
        closeFilterBar={() => setOpenFilter((oldValue) => !oldValue)}
        show={openFilter}
      >
        <FilterFormGroup>
          <ContentFilter
            control={control}
            formState={formState}
            setValue={setValue}
          />
        </FilterFormGroup>
      </Filter>
    </>
  );
}

export { Uploads };
