import { useRef, useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';
import { Modal, ModalProps } from 'semantic-ui-react';

import { ButtonGDY } from '../../../../components/Form/ButtonGDY';
import { queryClient } from '../../../../services/queryClient';
import {
  createNewTraining,
  updateTraining,
} from '../../../../services/training';
import { TrainingDataForm } from '../../../../types/training';
import { FormContent } from '../FormContent';

type TrainingFormModalProps = ModalProps & {
  isOpen: boolean;
  initialValues?: TrainingDataForm;
  onRequestClose: () => void;
  closeModalOnSave: () => void;
};

function TrainingFormModal({
  isOpen,
  initialValues,
  onRequestClose,
  closeModalOnSave,
  ...rest
}: TrainingFormModalProps) {
  const { t } = useTranslation();
  const formRef = useRef<HTMLFormElement>(null);

  const { control, formState, handleSubmit, setValue } =
    useForm<TrainingDataForm>({
      ...(initialValues && {
        defaultValues: initialValues,
      }),
    });

  const createTraining = useMutation(
    async (data: TrainingDataForm) => {
      return createNewTraining(data);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('trainings');
        closeModalOnSave();
        toast.success(t('components.toast.success.create'));
      },
      onError: () => {
        toast.error(t('components.toast.error.create'));
      },
    }
  );

  const editTraining = useMutation(
    async (data: TrainingDataForm) => {
      return updateTraining(data);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('trainings');
        closeModalOnSave();
        toast.success(t('components.toast.success.edit'));
      },
      onError: () => {
        toast.error(t('components.toast.error.edit'));
      },
    }
  );

  const onSubmit = useCallback(
    async (data: TrainingDataForm) => {
      if (data.id) {
        await editTraining.mutateAsync(data);
      } else {
        await createTraining.mutateAsync(data);
      }
    },
    [editTraining, createTraining]
  );

  return (
    <Modal
      {...rest}
      size="small"
      open={isOpen}
      closeIcon
      className="training modComp"
      onClose={onRequestClose}
      closeOnDimmerClick={false}
    >
      <Modal.Header>{t('pages.training.form.title')}</Modal.Header>
      <FormContent
        setValue={setValue}
        formRef={formRef}
        formState={formState}
        control={control}
        onSubmit={onSubmit}
        handleSubmit={handleSubmit}
      />
      <Modal.Actions>
        <ButtonGDY
          primary
          type="submit"
          onClick={() => {
            formRef.current?.handleSubmit();
          }}
        >
          {t('components.buttons.saveButton')}
        </ButtonGDY>
      </Modal.Actions>
    </Modal>
  );
}

export { TrainingFormModal };
