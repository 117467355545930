import { useCallback, useEffect } from 'react';
import ReactGA from 'react-ga4';
import {
  Navigate,
  Route,
  Routes as Switch,
  useLocation,
} from 'react-router-dom';

import { Layout } from '../components/Layout';
import { useAuth } from '../hooks/useAuth';
import { useSubordinatesByLoggedUser } from '../hooks/useSubordinatesByLoggedUser';
import {
  Home,
  Development,
  DevelopmentPDF,
  Profiles,
  Structure,
  Uploads,
  Messages,
  Training,
  Employees,
  Reports,
} from '../pages';
import { Login } from '../pages/Login';
import { Role } from '../types/roles';
import cookies from '../utils/cookies';

function RequireAuth({ children }: { children: JSX.Element }) {
  const token = cookies.getCookie('@TOKEN');

  if (!token) {
    return <Navigate to="/auth" />;
  }

  return children;
}

function Routes() {
  const { user } = useAuth();
  const location = useLocation();

  useEffect(() => {
    ReactGA.send({
      hitType: 'pageview',
      page: location.pathname + location.search,
    });
  }, [location.pathname, location.search]);

  const { hasSubordinates } = useSubordinatesByLoggedUser();

  const getRoutePermission = useCallback(
    (roleId: number) => {
      return user?.roles?.map(({ id }) => id).includes(roleId);
    },
    [user?.roles]
  );

  return (
    <Switch>
      <Route
        path="/"
        element={
          <RequireAuth>
            <Layout />
          </RequireAuth>
        }
      >
        <Route path="/" element={<Navigate to="/web" />} />
        <Route path="/web" element={<Home />} />

        <Route
          path="development/:mandatoryId/:sbuId"
          element={<Development />}
        />

        {getRoutePermission(Role.Structure) && (
          <Route path="/structure" element={<Structure />} />
        )}

        {getRoutePermission(Role.Training) && (
          <Route path="/training" element={<Training />} />
        )}

        {getRoutePermission(Role.Messages) && (
          <Route path="/messages" element={<Messages />} />
        )}

        {getRoutePermission(Role.Profiles) && (
          <Route path="/profiles" element={<Profiles />} />
        )}

        {getRoutePermission(Role.Employees) && (
          <Route path="/employees" element={<Employees />} />
        )}

        {getRoutePermission(Role.Uploads) && (
          <Route path="/uploads" element={<Uploads />} />
        )}

        {(getRoutePermission(Role.Reports) || hasSubordinates) && (
          <Route path="/reports" element={<Reports />} />
        )}

        <Route
          path="development/:mandatoryId/:sbuId/print"
          element={<DevelopmentPDF />}
        />
      </Route>

      <Route path="/auth" element={<Login />} />
    </Switch>
  );
}

export default Routes;
