import { Table } from 'semantic-ui-react';
import styled from 'styled-components';

export const Container = styled(Table.Body)`
  .conectLineBox {
    height: 140px;

    display: flex;
    flex-direction: column;

    .conectLine {
      height: calc(33.14px / 2);
      width: 100%;
      margin: 0 19px;
      border-radius: 0 0 0 5px;
      border-left: 2px solid #999999;
      border-bottom: 2px solid #999999;
    }
  }
`;

export const SubTable = styled.span``;
