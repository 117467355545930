import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';
import { Button, Icon, Loader } from 'semantic-ui-react';

import { WelcomeBar } from '../../components/WelcomeBar';
import { useEmployeeByUserId } from '../../hooks/useEmployeeByUserId';
import { useMandatoryDetails } from '../../hooks/useMandatoryDetails';
import { useMessageByType } from '../../hooks/useMessageByType';
import { getMandatoryById } from '../../services/mandatories';
import { getMessagesList } from '../../services/messages';
import { getTrainingTypeList } from '../../services/training';
import { PageOne } from './components/ReportPages/PageOne';
import { PageTwo } from './components/ReportPages/PageTwo';
import { Container, Content, Topbar, TopbarAndContent } from './styles';

export function Development() {
  const { t } = useTranslation();
  const { sbuId } = useParams<'sbuId'>();
  const { mandatoryId } = useParams<'mandatoryId'>();
  const { state: userId } = useLocation();
  const [showWelcomeBar, setshowWelcomeBar] = useState(true);

  const [pageNumberParam, setPageNumberParam] = useSearchParams({ page: '1' });

  const navigate = useNavigate();
  const { pathname } = useLocation();

  const storedUserId = useMemo((): string | undefined => {
    if (userId) {
      sessionStorage.setItem('@userId', userId);

      return userId;
    }

    if (sessionStorage.getItem('@userId')) {
      return String(sessionStorage.getItem('@userId'));
    }

    return undefined;
  }, [userId]);

  const { associatedData, isAssociatedDataLoading } = useEmployeeByUserId({
    mandatoryId: Number(mandatoryId),
    sbuId: Number(sbuId),
    userId: String(storedUserId),
  });

  const { mandatoryDetails, isLoadingMandatoryDetails } = useMandatoryDetails({
    mandatoryId: Number(mandatoryId),
    sbuId: Number(sbuId),
    userId: String(storedUserId),
  });

  useEffect(() => {
    if (pageNumberParam.get('page') === '1') {
      setshowWelcomeBar(true);
    }
    if (pageNumberParam.get('page') === '2') {
      setshowWelcomeBar(false);
    }
  }, [pageNumberParam]);

  const { data: mandatory, isLoading: isLoadingMandatoryById } = useQuery(
    ['MANDATORY', mandatoryId],
    async () => {
      return getMandatoryById(Number(mandatoryId));
    },
    { enabled: !!mandatoryId }
  );

  const getMandatoryYear = useCallback(() => {
    if (isLoadingMandatoryById) {
      return <Loader inline active size="small" />;
    }

    if (!isLoadingMandatoryById && mandatory) {
      return mandatory?.year.split('-')[0];
    }

    return undefined;
  }, [isLoadingMandatoryById, mandatory]);

  const { data: trainingTypeListIds } = useQuery(
    'TRAINING_TYPES_LIST_IDS',
    async () => {
      const res = await getTrainingTypeList();

      return res.map(({ id }) => id);
    }
  );

  const { message: welcomeMessage, isLoading: isLoadingWelcomeMessage } =
    useMessageByType({
      messageTypeName: 'WELCOME',
      lastMandatoryId: Number(mandatoryId),
    });

  const { message: noteMessage, isLoading: isLoadingNoteMessage } =
    useMessageByType({
      messageTypeName: 'NOTE',
      lastMandatoryId: Number(mandatoryId),
    });

  const { message: qrCode, isLoading: isLoadingQrCode } = useMessageByType({
    messageTypeName: 'QR CODE',
    lastMandatoryId: Number(mandatoryId),
  });

  const { data: messages, isLoading: isLoadingMessages } = useQuery(
    'MESSAGES_PER_CATEGORIEs',
    async () =>
      getMessagesList({
        mandatoryId: Number(mandatoryId),
        sbuId: Number(sbuId),
        trainingTypeId: trainingTypeListIds,
        page: undefined,
        size: undefined,
      }),
    {
      enabled: !!trainingTypeListIds,
    }
  );

  useEffect(() => {
    window.sessionStorage.setItem(
      '@welcome',
      JSON.stringify({
        message: welcomeMessage,
        isLoadingMessage: isLoadingWelcomeMessage,
        mandatory: associatedData?.mandatory,
        name: associatedData?.name,
        businessLine: associatedData?.businessLine,
        jobTitle: associatedData?.jobTitle,
        facilityDescription: associatedData?.facilityDescription,
      })
    );

    window.sessionStorage.setItem(
      '@page1',
      JSON.stringify({
        mandatoryDetails,
        isLoadingMandatoryDetails,
        noteMessage,
        isLoadingNoteMessage,
      })
    );

    window.sessionStorage.setItem(
      '@page2',
      JSON.stringify({
        mandatoryDetails,
        messages,
        isLoadingMessages,
        qrCode,
        isLoadingQrCode,
      })
    );
  }, [
    mandatoryDetails,
    isLoadingMandatoryDetails,
    messages,
    isLoadingMessages,
    noteMessage,
    isLoadingNoteMessage,
    qrCode,
    isLoadingQrCode,
    welcomeMessage,
    isLoadingWelcomeMessage,
    associatedData?.mandatory,
    associatedData?.name,
    associatedData?.businessLine,
    associatedData?.jobTitle,
    associatedData?.facilityDescription,
  ]);

  function handleReportPrint() {
    navigate(`${pathname}/print`);
  }

  return (
    <Container id="development__container">
      {showWelcomeBar && (
        <WelcomeBar
          message={welcomeMessage}
          isLoadingMessage={isLoadingWelcomeMessage}
          isEmployeeLoading={isAssociatedDataLoading}
          employee={associatedData}
        />
      )}

      <TopbarAndContent id="development__topbarAndContent">
        <Topbar className="topbar">
          <div className="topbar--title">
            <Icon name="graduation cap" />
            {t('pages.development.title')} ({getMandatoryYear()})
          </div>

          <span>
            <Button
              icon
              compact
              basic
              labelPosition="left"
              onClick={handleReportPrint}
              disabled={[
                isLoadingMandatoryDetails,
                isLoadingMessages,
                isLoadingNoteMessage,
                isLoadingQrCode,
                isLoadingWelcomeMessage,
              ].some((el) => !!el)}
            >
              <Icon name="print" />
              {t('pages.development.topbar.printReport')}
            </Button>

            <Button.Group compact basic>
              <Button
                content={t('pages.development.topbar.pageOne')}
                onClick={() => setPageNumberParam({ page: '1' })}
                active={pageNumberParam.get('page') === '1'}
              />

              <Button
                content={t('pages.development.topbar.pageTwo')}
                onClick={() => setPageNumberParam({ page: '2' })}
                active={pageNumberParam.get('page') === '2'}
              />
            </Button.Group>
          </span>
        </Topbar>

        <Content id="development__content">
          {pageNumberParam.get('page') === '2' ? (
            <PageTwo
              trainings={mandatoryDetails}
              messages={messages}
              isLoadingMessages={isLoadingMessages}
              qrCode={qrCode}
              isLoadingQrCode={isLoadingQrCode}
            />
          ) : (
            <PageOne
              mandatoryDetails={mandatoryDetails}
              isLoadingMandatoryDetails={isLoadingMandatoryDetails}
              noteMessage={noteMessage}
              isLoadingNoteMessage={isLoadingNoteMessage}
            />
          )}
        </Content>
      </TopbarAndContent>
    </Container>
  );
}
