import { useTranslation } from 'react-i18next';
import { Header, Icon } from 'semantic-ui-react';

export function TableNoDataFound() {
  const { t } = useTranslation();
  return (
    <Header>
      <Icon name="dont" />
      <Header.Content>
        {t('pages.general.noDataFound')}
        <Header.Subheader>
          {t('pages.general.noDataFoundForFilter')}
        </Header.Subheader>
      </Header.Content>
    </Header>
  );
}
