import { useTranslation } from 'react-i18next';
import { Table as TableSemantic, Pagination, Icon } from 'semantic-ui-react';

import { TableNoDataFound } from '../../../../components/TableNoDataFound';
import { TableBody } from '../TableBody';

interface ITableProps {
  handleFormModalOpen: (structure: any) => void;
  handleDeleteModalOpen: (structureId: number) => void;
  structures: any;
  page: number;
  handlePageChange: any;
}

function Table({
  handleFormModalOpen,
  handleDeleteModalOpen,
  handlePageChange,
  structures,
  page,
}: ITableProps) {
  const { t } = useTranslation();
  return (
    <>
      <div className="table__scrollable">
        <TableSemantic className="table" unstackable>
          <TableSemantic.Header className="thead">
            <TableSemantic.Row className="tr">
              <TableSemantic.HeaderCell
                width={1}
                className="th id"
                textAlign="center"
                scope="col"
              >
                {t('pages.structure.id')}
              </TableSemantic.HeaderCell>

              <TableSemantic.HeaderCell
                className="th sbuCol"
                textAlign="center"
                scope="col"
              >
                {t('pages.structure.sbu')}
              </TableSemantic.HeaderCell>

              <TableSemantic.HeaderCell
                className="th country"
                textAlign="center"
                scope="col"
              >
                {t('pages.structure.countryLocation')}
              </TableSemantic.HeaderCell>

              <TableSemantic.HeaderCell
                className="th language"
                textAlign="center"
                scope="col"
              >
                {t('pages.structure.language')}
              </TableSemantic.HeaderCell>

              <TableSemantic.HeaderCell className="th action">
                <Icon name="cog" size="large" />
              </TableSemantic.HeaderCell>
            </TableSemantic.Row>
          </TableSemantic.Header>
          <TableBody
            structure={structures?._embedded?.sbus}
            handleFormModalOpen={handleFormModalOpen}
            handleDeleteModalOpen={handleDeleteModalOpen}
          />
        </TableSemantic>
      </div>

      <div className="table__pagination">
        {structures?._embedded ? (
          <Pagination
            page={page}
            totalPages={structures?.page?.totalPages ?? 0}
            handlePageChange={handlePageChange}
          />
        ) : (
          <TableNoDataFound />
        )}
      </div>
    </>
  );
}

export { Table };
