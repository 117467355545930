import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';

import { downloadTemplateRequest } from '../../../services/imports';

export function useHandleDownloadTemplate() {
  const { t } = useTranslation();
  const {
    mutateAsync: handleDownloadTemplate,
    isLoading: isLoadingDownloadTemplate,
  } = useMutation(
    async () => {
      return downloadTemplateRequest();
    },
    {
      onSuccess: (data: Blob) => {
        const url = window.URL.createObjectURL(data);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'TDS_TEMPLATE.xlsx');
        link.click();
      },
      onError: () => {
        toast.error(t('components.toast.error.get'));
      },
    }
  );

  return {
    handleDownloadTemplate,
    isLoadingDownloadTemplate,
  };
}
