import { Dispatch, SetStateAction } from 'react';
import { Control, UseFormSetValue, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';

import { useMandatoryOptions } from '../../../hooks/useMandatoryOptions';
import { createMandatoryRequest } from '../../../services/mandatory';
import { queryClient } from '../../../services/queryClient';
import { ImportParams } from '../../../types/import';
import { CreateUpdateMandatoryResponse } from '../../../types/mandatory';

interface IProps {
  control: Control<ImportParams, any>;
  setValue: UseFormSetValue<ImportParams>;

  query: ImportParams;
  setQuery: Dispatch<SetStateAction<ImportParams>>;

  isCopyPreviousYearMessages: boolean;

  setOpenConfirmNewYear: Dispatch<SetStateAction<boolean>>;
}

export function useHandleCreateNewYear({
  control,
  setValue,

  query,
  setQuery,

  isCopyPreviousYearMessages,

  setOpenConfirmNewYear,
}: IProps) {
  const { t } = useTranslation();
  const watchRegion = useWatch({ control, name: 'region' });
  const watchSbuId = useWatch({ control, name: 'sbuId' });

  const { mandatoryOptions } = useMandatoryOptions({
    watchSBU: watchSbuId as number,
    watchRegion: watchRegion as string,
    setValue,
  });

  const {
    mutateAsync: handleCreateNewYear,
    isLoading: isLoadingCreateNewYear,
  } = useMutation(
    async () => {
      const mandatory = mandatoryOptions?.find(
        (item) => item.value === query.mandatoryId
      );

      return createMandatoryRequest(
        {
          sbuId: query.sbuId as number,
          year: `${String(Number(mandatory?.text) + 1)}-01-01`,
          released: false,
        },
        isCopyPreviousYearMessages
      );
    },
    {
      onSuccess: (data: CreateUpdateMandatoryResponse) => {
        setQuery({ sbuId: data.sbuId, mandatoryId: data.id });
        toast.success(t('components.toast.success.create'));
        queryClient.invalidateQueries('imports');
        setOpenConfirmNewYear((oldValue) => !oldValue);
      },
      onError: () => {
        toast.error(t('components.toast.error.create'));
      },
    }
  );

  return {
    handleCreateNewYear,
    isLoadingCreateNewYear,
  };
}
